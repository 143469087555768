import { Component, Input } from '@angular/core';

@Component({
  selector: 'ms-logo',
  templateUrl: './ms-logo.component.html',
  styleUrls: ['./ms-logo.component.scss'],
})
export class MsLogoComponent {
  @Input() public type: 'range' | 'medallion' = 'range';
  @Input() public title: string = 'MountainSeed';

  // TODO: think how to make it reusable / list of colors / names of colors
  @Input() public color: string = 'primary';
  public _color: string = '#003594';

  constructor() {}

  public ngOnInit(): void {
    this._color = this.color === 'white' ? 'white' : '#003594';
  }
}
