<div
  *ngIf="
    control &&
    control.invalid &&
    ((control.dirty && control.touched) ||
      (skipDirtyAndTouched && control.value))
  "
  class="form-control-errors"
>
  <small *ngFor="let error of errors" class="p-error block">
    {{ validationMessages[error] || validationMessages[error.toLowerCase()] }}
  </small>
</div>
