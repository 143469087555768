import {
  ControlContainer,
  ControlValueAccessor,
  DefaultValueAccessor,
  FormControl,
  FormControlDirective,
} from '@angular/forms';
import { Component, Injector, Input, ViewChild } from '@angular/core';

@Component({
  template: '',
})
export class ControlValueAccessorConnector implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  @Input()
  formControl: FormControl;

  @Input()
  formControlName: string;

  get control(): FormControl {
    return (
      this.formControl ||
      this.controlContainer?.control?.get(this.formControlName)
    );
  }

  constructor(private injector: Injector) {}

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    return DefaultValueAccessor.prototype.registerOnChange.call(
      this,
      (value) => {
        const trimmed = typeof value === 'string' ? value.trim() : value;
        return fn(trimmed);
      }
    );
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // @ts-ignore
    this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
  }
}
