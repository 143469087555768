import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { Button, ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MsLogoModule } from '../ms-logo';
import { MsWaffleMenuModule } from '../waffle-menu';
import { TopNavigationBarComponent } from './top-navigation-bar.component';

@NgModule({
  imports: [
    CommonModule,
    //
    AvatarModule,
    ButtonModule,
    MenuModule,
    MenubarModule,
    //
    MsWaffleMenuModule,
    MsLogoModule,
  ],
  exports: [TopNavigationBarComponent],
  declarations: [TopNavigationBarComponent],
  providers: [],
})
export class TopNavigationBarModule {}
