import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { enterKey } from '@ms/angular-workspace/dist/core';

@Component({
  selector: 'ms-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MsAutocompleteComponent),
      multi: true,
    },
  ],
})
export class MsAutocompleteComponent implements ControlValueAccessor {
  @Input() public dataKey: string = 'id';
  @Input() public placeholder: string = '';
  @Input() public multiple: boolean = false;
  @Input() public suggestions: any[] = [];
  @Input() disabled: boolean = false;
  @Input() public field: string;
  @Input() public loading = false;
  @Input() public delay = 300;
  @Input() public minLength = 2;
  @Input() public style: any = '';
  @Input() public inputStyle: any = '';
  @Input() public completeSuggestionsMethod: Function;

  @Output() public complete: EventEmitter<string> = new EventEmitter();
  @Output() public select = new EventEmitter<AutoCompleteSelectEvent>();
  @Output() public input = new EventEmitter();
  @Output() public onKeyUp: EventEmitter<any> = new EventEmitter();

  @ViewChild(AutoComplete)
  private autocompleteComponent: AutoComplete;

  public value: any;
  onValidatorChange: Function;

  constructor() {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public writeValue(value: any): void {
    if (this.field && !!value) {
      this.value = {
        ...this.value,
        [this.field]: value,
      };
    } else {
      this.value = value;
    }
  }

  public onSelect($event: AutoCompleteSelectEvent): void {
    this.select.next($event);
  }

  public unselect($event: any): void {
    this.onChange(undefined);
  }

  public keyup($event: any): void {
    // Do not emit if an option is highlighted and the enter key is pressed.
    // onSelect will handle emitting the value in that scenario
    if (
      !(this.autocompleteComponent.highlightOption && $event?.key === enterKey)
    ) {
      this.onKeyUp.emit($event);
    }
  }

  public blur($event: any): void {}

  public hide(): void {
    this.autocompleteComponent.hide();
  }

  public onComplete($event: AutoCompleteCompleteEvent): void {
    this.complete.emit($event.query);
  }

  public onClear($event: any): void {
    this.onTouched && this.onTouched();
    this.onChange && this.onChange(undefined);
  }

  private onChange: Function = (value: any) => {};

  private onTouched: Function = (value: any) => {};
}

export function msAutocompleteParseQuery(value: any, field: string) {
  return typeof value === 'string' ? value : (value && value[field]) || '';
}
