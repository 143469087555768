import { Component, OnInit, Input } from '@angular/core';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ms-waffle-menu',
  templateUrl: './ms-waffle-menu.component.html',
  styleUrls: ['./ms-waffle-menu.component.scss'],
})
export class MsWaffleMenuComponent {
  icon = faTh;

  @Input() items: MenuItem[];

  onClick($event: MouseEvent) {
    $event.preventDefault();
  }
}
