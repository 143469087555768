<div
  class="bg-primary flex align-items-center px-3 w-full"
  [ngClass]="sticky ? 'sticky' : ''"
>
  <!-- Brand -->
  <a class="flex align-items-center px-3" href="/">
    <ms-logo
      type="medallion"
      color="white"
      class="mx-3 ms--top-navigation-bar__logo"
    ></ms-logo>
    <span class="font-normal text-white text-base"
      >MountainSeed {{ appName || "" }}</span
    >
  </a>

  <!-- Main Navigation -->
  <nav class="align-self-stretch flex" role="navigation" aria-label="Main">
    <p-menubar
      styleClass="ms--top-navigation-bar__main-nav py-0 h-full"
      [model]="items"
      class="border-noround block"
    ></p-menubar>
  </nav>

  <!-- User Menu -->
  <div class="ml-auto">
    <button
      class="p-reset px-3 w-5rem"
      type="button"
      pButton
      (click)="menu.toggle($event)"
    >
      <!-- Avatar -->
      <p-avatar
        [label]="initials || ''"
        [icon]="initials ? '' : 'pi pi-user'"
        shape="circle"
        [styleClass]="'p-reset bg-teal-500'"
      ></p-avatar>
      <span
        class="p-button-icon pi pi-angle-down p-button-icon-right ml-2"
        aria-hidden="true"
      ></span>
    </button>
    <p-menu #menu [popup]="true" [model]="userMenuItems"></p-menu>
  </div>
</div>
