import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseFloatFromCurrency',
})
export class ParseFloatFromCurrencyPipe implements PipeTransform {
  transform(value: string): number {
    return value ? Number.parseFloat(value.replace(/[$,]+/g, '')) : 0;
  }
}
