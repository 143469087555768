import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { AuthGuard } from '@ms/angular-workspace/dist/core';
import { AnalyticsLayoutComponent } from './components/analytics-layout/analytics-layout.component';
import { MiPasswordExpiredGuard } from './guards/mi-password-expired.guard';
import { MiPermissionsGuard } from './guards/mi-permissions.guard';

import { SecureContainerComponent } from './modules/secure/components/secure-container/secure-container.component';

// TODO: Enable password expiration for prod
const HOME_PAGE = 'secure/dashboard';
const routes: Routes = [
  {
    path: '',
    redirectTo: HOME_PAGE,
    pathMatch: 'full',
  },
  {
    path: 'secure',
    component: AnalyticsLayoutComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
      () => inject(MiPasswordExpiredGuard).canActivate(),
      () => inject(MiPermissionsGuard).canActivate(),
    ],
    children: [
      {
        path: '',
        component: SecureContainerComponent,
        loadChildren: () =>
          import('./modules/secure/secure.module').then((m) => m.SecureModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: HOME_PAGE,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
