import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'ms-date-range-picker',
  templateUrl: './ms-date-range-picker.component.html',
  styleUrls: ['./ms-date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsDateRangePickerComponent {
  @Output() startDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() startDate: Date | null;

  @Output() endDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() endDate: Date | null;

  @Output() dateRangeChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() maxDate: Date;

  @Input() calendarClass: string | undefined;

  defaultStartCalendarClass = 'start-date-calendar w-9rem';
  defaultEndCalendarClass = 'end-date-calendar w-9rem';

  constructor() {}

  startDateChanged(newStartDate: any) {
    this.startDate = newStartDate;
    this.startDateChange.emit(newStartDate);
  }

  endDateChanged(newEndDate: any) {
    this.endDate = newEndDate;
    this.endDateChange.emit(newEndDate);
  }

  dateRangeChanged() {
    const opts: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    this.dateRangeChange.emit({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }
}
