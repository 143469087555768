import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toTitleCase' })
export class ToTitleCasePipe implements PipeTransform {
  transform(value: any) {
    if (typeof value === 'string') {
      if (value === '' || value == undefined) {
        return '';
      }
      return this.toTitleCase(value);
    } else {
      return value;
    }
  }
  toTitleCase(input: string) {
    const transformed = new TitleCasePipe().transform(input);
    // capitalize letters after slash
    return transformed.replace(/\/\w/g, (match) => match.toUpperCase());
  }
}
