import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ms-top-navigation-bar',
  templateUrl: './top-navigation-bar.component.html',
  styleUrls: ['./top-navigation-bar.component.scss'],
})
export class TopNavigationBarComponent implements OnInit {
  @Input() items: MenuItem[];
  @Input() userMenuItems: MenuItem[];
  @Input() products: MenuItem[] | undefined;

  @Input() initials: string;
  @Input() appName: string;
  @Input() sticky: boolean = false;

  productSwitchMenu: MenuItem[];

  constructor() {}

  ngOnInit(): void {
    if (this.products && this.products.length > 0) {
      this.productSwitchMenu = [
        {
          label: 'Switch to',
          items: this.products,
        },
      ];
    }
  }
}
