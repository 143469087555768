import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { MsAuthState } from '@ms/angular-workspace/dist/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class MiPermissionsGuard implements CanActivate, CanActivateChild {
  private _userProfile$: Observable<any> = inject(Store).select(
    MsAuthState.user
  );

  canActivate() {
    return this.redirectIfNoSubscription();
  }

  canActivateChild() {
    return this.redirectIfNoSubscription();
  }

  private redirectIfNoSubscription() {
    return this._userProfile$.pipe(
      filter((user) => !!user),
      map((claims: any) => <boolean>claims?.isMISubscribed),
      tap((hasSubscription) => {
        if (!hasSubscription) {
          window.location.href = environment.productUrls.authUrl;
        }
      })
    );
  }
}
