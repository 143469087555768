<div class="p-input-icon-left w-full">
  <i *ngIf="showIcon" class="pi pi-search"></i>
  <input
    type="text"
    class="w-full"
    pInputText
    [placeholder]="placeholder"
    (input)="onSearch($event)"
    [value]="inputValue"
  />
</div>
