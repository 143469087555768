import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsConfigModuleConfig } from './models';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigService {
  constructor(
    private http: HttpClient,
    private moduleConfig: MsConfigModuleConfig
  ) {}

  private _config: any;

  get config() {
    return this._config;
  }

  async loadConfig() {
    this._config = await this.http.get(this.moduleConfig.configUrl).toPromise();
  }
}
