import { IState } from '../models';
import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { StateContext, Selector, State } from '@ngxs/store';

@Injectable()
export class UserStateModel {
  UserAvailableStates: IState[] | undefined;
}
@State<UserStateModel>({
  name: 'user',
  defaults: {
    UserAvailableStates: undefined,
  },
})
@Injectable()
export class UserState {
  @Selector()
  public static getUserAvailableStates(state: UserStateModel) {
    return state.UserAvailableStates;
  }

  @Receiver({ type: '[User] Set User available States' })
  public static setUserAvailableStates(
    context: StateContext<UserStateModel>,
    action: EmitterAction<IState[]>
  ) {
    context.patchState({
      UserAvailableStates: action.payload,
    });
  }
}
