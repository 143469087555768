import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { SortIcon } from 'primeng/table';

@Component({
  selector: 'ms-sortIcon',
  templateUrl: './ms-sortIcon.component.html',
  styleUrls: ['./ms-sortIcon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsSortIconComponent extends SortIcon {
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  sortOrder: number;
}
