import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toStringDate' })
export class ToStringDatePipe implements PipeTransform {
  transform(date: any) {
    const opts: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    if (typeof date === 'string') {
      if (date === '' || date == undefined) {
        return '';
      }
      return new Date(date).toLocaleDateString('en-US', opts);
    }
    return date?.toLocaleDateString('en-US', opts);
  }
}
