import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ApiSearchResponseTableData,
  ApiSearchSortDirections,
  tooltipShowDelay,
} from '@ms/angular-workspace/dist/core';
import { MenuItem } from 'primeng/api';
import { FrozenColumn, Table, TableLazyLoadEvent } from 'primeng/table';
import {
  faAngleDown,
  faAngleRight,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';

export interface TableEvent {
  pageSize: number;
  currentPage: number;
  filters: any[];
  sortField?: string | undefined;
  sortDirection?: ApiSearchSortDirections;
}

export interface TableActionEvent {
  action: string;
  items: TableItem;
  isSelectedOnAllPages?: boolean;
}

export interface IExpandableOptions {
  hideValue?: boolean;
}

export interface IColumnOptions extends IFrozenColumnOptions {
  sortable: boolean;
  template: string;
  hidden?: boolean;
  sortField?: string;
  tooltip?: string;
  defaultValue?: string;
  preserveCase?: boolean;
}

type AlignColumns = 'left' | 'right';

export interface IFrozenColumnOptions {
  frozen: boolean;
  alignFrozen: AlignColumns;
  expandableOptions?: IExpandableOptions;
  isDisabled?(tableData: TableItem): boolean;
}

const DEFAULT_FROZEN_COLUMN_OPTIONS = {
  frozen: false,
  alignFrozen: <AlignColumns>'left',
  expandableOptions: {
    hideValue: false,
  },
};

const DEFAULT_COLUMN_OPTIONS = {
  sortable: false,
  hidden: false,
  template: 'default',
  ...DEFAULT_FROZEN_COLUMN_OPTIONS,
};

export interface TableActionMenuItem {
  label: string;
  action: string;
  icon?: string;
  isVisible?(tableData: TableItem): boolean;
  visibleInHeader?: boolean;
}

export interface TableAction {
  visibleInHeader?: boolean;
  type: 'menu' | 'button' | 'iconButton';
  icon?: string;
  label?: string;
  action: string;
  menuItems?: TableActionMenuItem[];
  tooltip?: string;

  isVisible?(appraiser: TableItem): boolean;
}

export class Column {
  public options: IColumnOptions = DEFAULT_COLUMN_OPTIONS;
  protected type: 'select' | 'data' | 'actions' | 'expand';

  constructor(
    private _key: string,
    private _label: string | number | boolean,
    options: Partial<IColumnOptions> = {}
  ) {
    this.options = { ...DEFAULT_COLUMN_OPTIONS, ...options };
  }

  protected _actions: TableAction[];

  get actions() {
    return this._actions;
  }

  get key(): string {
    return this._key;
  }

  get label() {
    return this._label;
  }

  get tooltip() {
    return this.options.tooltip;
  }

  get sortable() {
    return this.options.sortable;
  }

  get sortKey() {
    return this.options.sortField || this.key;
  }

  get frozen() {
    return this.options.frozen;
  }

  get alignFrozen() {
    return this.options.alignFrozen;
  }

  get hidden() {
    return this.options.hidden;
  }

  get hideExpandableValue() {
    return this.options.expandableOptions?.hideValue ?? false;
  }

  get isSelect() {
    return this.type === 'select';
  }

  get isData(): boolean {
    return this.type === 'data';
  }

  get isExpand() {
    return this.type === 'expand';
  }

  isActions(rowData: TableItem): boolean {
    return (
      this.type === 'actions' &&
      this.actions &&
      this.actions?.some(
        (item) =>
          !item.isVisible ||
          item.isVisible(rowData) ||
          !item.menuItems ||
          item.menuItems?.some(
            (action) => !action.isVisible || action.isVisible(rowData)
          )
      )
    );
  }

  getColumnClass(el: string) {
    return `ms-${el}-${this.key.replace('.', '-')}`;
  }
}

export class DataColumn extends Column {
  public type = <const>'data';
}

export class ExpandColumn extends Column {
  public type = <const>'expand';

  constructor(options: Partial<IFrozenColumnOptions> = {}) {
    super('expand', '', options);
  }
}

export class SelectColumn extends Column {
  public type = <const>'select';

  constructor(options: Partial<IFrozenColumnOptions> = {}) {
    super('select', '', options);
  }
}

export class ActionsColumn extends Column {
  public type = <const>'actions';

  constructor(
    label: string,
    protected _actions: TableAction[],
    options: Partial<IFrozenColumnOptions> = {}
  ) {
    super('actions', label, options);
  }
}

export type TableItem = any;

export interface TableData {
  items: TableItem[];
  totalItemCount: number;
  itemCount: number;
  sortField: string | undefined;
  sortDirection: ApiSearchSortDirections.Ascending;
}

@Component({
  selector: 'ms-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default, // TODO: try to use Push model
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public templates: { name: string; ref: TemplateRef<any> }[] = [];
  @Input() public columns: Column[] | null = [];

  @Input() data: ApiSearchResponseTableData<TableItem> | null = {
    items: [],
    totalItemCount: 0,
    itemCount: 10,
    sortField: undefined,
    sortDirection: ApiSearchSortDirections.Ascending,
  };

  @Input() public allItems: any[] = [];
  @Input() public loading: boolean = true;
  @Input() public sortField: string;
  @Input() public sortOrder = -1;
  @Input() public selectionMode: 'multiple' | 'single';
  @Input() public paginator: boolean = true;
  @Input() public showCaption: boolean = true;
  @Input() public scrollHeight: string;
  @Input() public scrollable: boolean = true;
  @Input() public groupRowsBy: string;
  @Input() public emptyTableMessage: string = 'No items found.';
  @Input() disableSelectionForSelectAll: boolean = false;
  @Input() public selectedItems: TableItem[];
  @Input() public isStickyHeader: boolean;
  @Input() public styleClass: string;
  @Input() rows = 10;
  @Input() expandableProperty: string | null;
  @Input() showHeader: boolean = true;
  @Input() scrollDirection: string = 'both';
  @Input() virtualScroll: boolean = false;
  @Input() virtualRowHeight: number;
  @Input() lazy: boolean = true;
  @Input() autoLayout: boolean = true;
  @Input() primarySortFields: String[] = [];
  @Input() expandedItems: { [s: string]: boolean } = {};
  @Input() clearItems$: Subject<null> = new Subject();

  @Output() public change = new EventEmitter<TableEvent>();
  @Output() public action = new EventEmitter<TableActionEvent>();
  @Output() public select = new EventEmitter<TableItem>();
  @Output() public unselect = new EventEmitter<TableItem>();
  @Output() public selectionChange = new EventEmitter<TableItem[]>();
  public page = 0;
  public multipleActions: TableAction[] = [];
  @ViewChild('dateValueTemplate', { static: true })
  dateValueTemplate: TemplateRef<any>;
  @ViewChild('costValueTemplate', { static: true })
  costValueTemplate: TemplateRef<any>;
  @ViewChild('numberTemplate', { static: true })
  numberTemplate: TemplateRef<any>;
  @ViewChild('percentTemplate', { static: true })
  percentTemplate: TemplateRef<any>;
  private currentState: TableLazyLoadEvent;
  private currentAction: string | null = null;
  public allSelected: boolean | null = null;
  public allExpanded: boolean | null = null;

  @ViewChild('table') private table: Table;
  @ViewChildren(FrozenColumn) frozenColumns: QueryList<FrozenColumn>;
  public selectAllCheckboxCtrl = new FormControl(null);
  public isSelectedOnAllPages: boolean;
  faEllipsisV = faEllipsisV;
  faAngleDown = faAngleDown;
  faAngleRight = faAngleRight;
  tooltipShowDelay = tooltipShowDelay;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    const changedData = <TableItem[]>changes.data?.currentValue?.items;
    if (changedData) {
      this.allSelected = this.isAllSelected(changedData);
      if (this.isSelectedOnAllPages) {
        this.selectedItems = changedData;
      } else if (this.selectedItems) {
        // wait 100 ms to let the selection check settle.
        // This is a workaround for TE-2524 where the selection is not updated correctly after changing the sort order.
        // We should check if this is still needed after updating primeng to a newer version (currently 15.0.0-rc.1)
        setTimeout(() => {
          this.table.updateSelectionKeys();
          this.table.tableService.onSelectionChange();
        }, 100);
      }
    }
    this.currentAction = null;
  }

  public ngOnInit(): void {
    this.multipleActions = this.getMultipleActions();

    this.clearItems$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.unSelectAll();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  isAllSelected(newArray: TableItem[] | undefined): boolean | null {
    if (newArray && this.selectedItems?.length) {
      if (
        this.isSelectedOnAllPages ||
        newArray.every((item) =>
          this.selectedItems.find(({ id }) => id === item.id)
        )
      ) {
        return true;
      } else if (
        newArray.some((item) =>
          this.selectedItems.find(({ id }) => id === item.id)
        )
      ) {
        return false;
      }
      return null;
    }
    return null;
  }

  public getPropertyByPath(item: TableItem, fieldPath: string | null) {
    if (!fieldPath) {
      return item;
    }

    const paths = fieldPath.split('.');

    return paths.reduce((value, path) => value && value[path], item);
  }

  public onPageSizeChange(pageSize: number): void {
    this.table.onPageChange({
      first: this.page * pageSize,
      rows: pageSize,
    });
  }

  getMultipleActions(): TableAction[] {
    const actions: TableAction[] = [];
    const columnsWithActions: Column[] | undefined = this.columns?.filter(
      (column) => column.isActions
    );
    if (columnsWithActions) {
      columnsWithActions.forEach((column: Column) => {
        column.actions &&
          column.actions.forEach((action: TableAction) => {
            if (action.type === 'menu') {
              actions.push(...(action.menuItems as TableAction[]));
            } else {
              actions.push(action);
            }
          });
      });
    }
    return actions;
  }

  isDisabledMenu(action: TableAction, rowData: TableItem) {
    return !(
      action?.menuItems &&
      (!action.isVisible || action.isVisible(rowData)) &&
      action.menuItems?.some(
        (item) => !item.isVisible || item.isVisible(rowData)
      )
    );
  }

  getColumnClass(key: string, el: string) {
    return `ms-${el}-${key.replace('.', '-')}`;
  }

  onAction(item: TableItem, action: string) {
    this.currentAction = action;
    this.action.next({ items: [item], action });
  }

  onMultipleAction(action: string) {
    this.action.next({
      items: [...this.selectedItems],
      action,
      isSelectedOnAllPages: this.isSelectedOnAllPages,
    });
  }

  isShowMultipleAction(action: TableAction) {
    return (
      !action.isVisible ||
      this.selectedItems.every(
        (tableItem: TableItem) =>
          action.isVisible && action.isVisible(tableItem)
      )
    );
  }

  update(event: TableLazyLoadEvent) {
    if (
      this.virtualScroll &&
      this.rows !== event.rows &&
      event.rows &&
      ![10, 25, 50].includes(event.rows) &&
      this.data &&
      this.currentState
    ) {
      return;
    }

    if (
      this.virtualScroll &&
      event.rows &&
      ![10, 25, 50].includes(event.rows) &&
      this.rows !== event.rows
    ) {
      event.rows = this.rows;
    }

    this.currentState = event;

    if (!this.paginator) event.rows = -1;

    // if sort field is just a string, set sortField to a column. Otherwise split the string if it is an array
    let sortField = null;

    if (event.sortField) {
      sortField = Array.isArray(event.sortField)
        ? event.sortField
        : event.sortField?.split(',');
    }

    this.change.emit({
      currentPage: (event.first || 0) / (event.rows || 10),
      pageSize: event?.rows || 10,
      sortDirection:
        event.sortOrder === 1
          ? ApiSearchSortDirections.Ascending
          : ApiSearchSortDirections.Descending,
      sortField: [
        ...new Set([...(sortField ?? []), ...this.primarySortFields]),
      ].join(','),
      filters: [],
    });
  }

  public onRowSelect(event: TableItem): void {
    this.allSelected = this.isAllSelected(this.data?.items);
    this.selectionChange.next(this.selectedItems);
  }

  public onRowUnSelect($event: TableItem): void {
    this.allSelected = this.isAllSelected(this.data?.items);
    this.isSelectedOnAllPages = false;
    this.selectionChange.next(this.selectedItems);
  }

  public selectAll(event: { value: boolean | null }, column: Column) {
    if (event.value === false) {
      this.allSelected = null; // disable false value
      this.isSelectedOnAllPages = false;
    }
    if (!this.selectedItems) {
      this.selectedItems = [];
    }
    this.data?.items.forEach((item: any) => {
      const index = this.selectedItems.findIndex(({ id }) => id === item.id);

      if (!(column?.options.isDisabled && column.options.isDisabled(item))) {
        if (event.value) {
          if (index === -1) {
            this.selectedItems.push(item);
          }
        } else if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    });

    this.selectedItems = [...this.selectedItems];
    this.selectionChange.next(this.selectedItems);
  }

  public unSelectAll() {
    this.selectedItems = [];
    this.allSelected = null;
    this.isSelectedOnAllPages = false;
    this.selectionChange.next(this.selectedItems);
  }

  selectOnAllPages() {
    this.isSelectedOnAllPages = !this.isSelectedOnAllPages;
    if (!this.isSelectedOnAllPages) {
      this.unSelectAll();
    } else {
      this.allItems.forEach((item) => {
        const index = this.selectedItems.findIndex(({ id }) => id === item.id);

        if (index === -1) {
          this.selectedItems.push(item);
        }
      });
      this.selectedItems = [...this.selectedItems];
      this.selectionChange.next(this.selectedItems);
    }
  }

  public toggleExpandAll() {
    if (!this.allExpanded) {
      this.data?.items.forEach((item: any) => {
        if (this.isExpandable(item)) {
          this.expandedItems[item.id] = true;
        }
      });
      this.allExpanded = true;
      return;
    }

    this.expandedItems = {};
    this.allExpanded = false;
  }

  onRowExpand() {
    if (
      Object.keys(this.expandedItems).length ===
      this.data?.items.filter((item: any) => this.isExpandable(item)).length
    ) {
      this.allExpanded = true;
    }
  }

  onRowCollapse() {
    this.allExpanded = false;
  }

  public setFirstPage() {
    if (this.table) {
      this.table.first = 0;
    }
  }

  public onSelectionChange($event: any): any {
    this.selectionChange.next(this.selectedItems);
  }

  public getMenuItems(
    menuItems: TableActionMenuItem[],
    rowData: TableItem
  ): MenuItem[] {
    const items = [];
    for (let item of menuItems) {
      if (item.isVisible == null || item.isVisible(rowData)) {
        items.push({
          ...item,
          rowData,
          command: () => this.onAction(rowData, item.action),
        });
      }
    }

    return items;
  }

  public getTemplate(column: Column): TemplateRef<any> | null {
    if (!this.table) {
      return null;
    }

    const standardTemplates =
      this.table.templates?.filter((tmpl) => tmpl.type === 'ms-cell') || [];

    return (
      this.templates.find(
        (template) => template.name === column.options.template
      )?.ref ||
      standardTemplates.find((tmpl) => tmpl.name === column.options.template)
        ?.template ||
      null
    );
  }

  public getRowExpansionTemplate(): TemplateRef<any> | null {
    if (!this.table) {
      return null;
    }
    return (
      this.templates.find(
        (template) => template.name === 'rowExpansionTemplate'
      )?.ref || null
    );
  }

  public getSelectedItems() {
    return this.selectedItems;
  }

  public hasFrozenColumns(columns: Column[]): boolean {
    return columns.some((c) => c.frozen);
  }

  public sort($event: any): void {
    // TODO: TBD
  }

  public updateStickyColumnPositions(): void {
    this.frozenColumns.toArray().forEach((col) => {
      col.updateStickyPosition();
    });
  }

  isExpandable(rowData: TableItem): boolean {
    return (
      this.getPropertyByPath(rowData, this.expandableProperty)?.length >= 1
    );
  }

  isSelectionDisabled(column?: Column, rowData?: TableItem) {
    return !!(
      (this.isSelectedOnAllPages && this.disableSelectionForSelectAll) ||
      (column?.options.isDisabled && column.options.isDisabled(rowData))
    );
  }

  cleanSelection(items: TableItem[]) {
    this.selectedItems = this.selectedItems.filter((x) => {
      return !items.some((item) => item.id === x.id);
    });
  }

  trackByFunction(index: number, item: TableItem) {
    return index || item?.id;
  }
}
