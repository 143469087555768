import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export namespace MsFileValidators {
  export function maxFileSize(size: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const files: File[] = c?.value;
      return !files?.length || files[0].size <= size
        ? null
        : {
            notAllowedFileSize: { valid: false },
          };
    };
  }

  export function allowMultiple(multiple: boolean): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const files: File[] = c?.value;
      return multiple || files?.length <= 1
        ? null
        : {
            notAllowedMultiple: { valid: false },
          };
    };
  }

  export function fileFormats(fileTypes: string[]): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const files: File[] = c?.value;
      return !fileTypes?.length ||
        !files?.length ||
        files.some((file) =>
          fileTypes.includes(`.${file.name.split('.').pop()}` || '')
        )
        ? null
        : {
            notAllowedFileFormat: { valid: false },
          };
    };
  }
}
