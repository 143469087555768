import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';

import { MsAutocompleteComponent } from './autocomplete.component';

@NgModule({
  imports: [CommonModule, AutoCompleteModule, FormsModule, ReactiveFormsModule],
  exports: [MsAutocompleteComponent],
  declarations: [MsAutocompleteComponent],
  providers: [],
})
export class MsAutocompleteModule {}
