import { Component } from '@angular/core';

@Component({
  selector: 'mi-secure-container',
  templateUrl: './secure-container.component.html',
  styleUrls: ['./secure-container.component.scss'],
})
export class SecureContainerComponent {
  constructor() {}
}
