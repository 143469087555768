import { AfterContentInit, Component, Input, OnChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ms-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent implements AfterContentInit, OnChanges {
  @Input() control: AbstractControl;
  @Input() skipDirtyAndTouched: boolean;
  @Input() customValidationMessages: { [key: string]: string };
  @Input() interpolatedValidators: [string, string | number][];

  validationMessages: { [key: string]: string } = {
    required: 'Required field',
    email: 'Invalid email',
    pattern: 'Invalid value',
    notAllowedMultiple: 'This import does not support multi-file',
    dateNotInFuture: 'Date should be in the future',
    notAllowedFileFormat: 'Wrong file format',
    notAllowedFileSize:
      'The file you are uploading exceeds the size limit. Please remove it and upload a smaller file-list-item',
    maxlength: 'Max length is {{ value }} characters',
    minlength: 'Min length is {{ value }} characters',
    noWhiteSpace: 'Invalid value',
    dropDownNotEqualsValue: `Should not be equal to current value`,
  };

  get errors() {
    if (!this.control || !this.control.errors) {
      return [];
    }

    return Object.keys(this.control.errors);
  }

  ngAfterContentInit(): void {
    this.updateMessages();
  }

  ngOnChanges(changes: any) {
    if (changes.customValidationMessages || changes.interpolatedValidators) {
      this.updateMessages();
    }
  }

  updateMessages() {
    this.validationMessages = Object.assign(
      {},
      this.validationMessages,
      this.customValidationMessages || {}
    );

    if (typeof this.interpolatedValidators === 'undefined') {
      return;
    }

    const interpolatedMessages: { [key: string]: string } = {};
    this.interpolatedValidators.map((rule) => {
      const validatorName = rule[0].toLowerCase();
      const interpolatedValue: any = rule[1];
      interpolatedMessages[validatorName] = this.validationMessages[
        validatorName
      ].replace('{{ value }}', interpolatedValue);
    });
    this.validationMessages = Object.assign(
      {},
      this.validationMessages,
      interpolatedMessages
    );
  }
}
