import { AbstractControl, ValidationErrors } from '@angular/forms';

export namespace MsWhiteSpaceValidators {
  export function noWhiteSpace(
    control: AbstractControl
  ): ValidationErrors | null {
    if ((control.value as string).trim().length === 0) {
      return { noWhiteSpace: true };
    }

    return null;
  }
}
