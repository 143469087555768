<div class="strong-password-check-wrapper">
  <ul class="strong-password-check-list">
    <li
      [ngClass]="{
        'strong-password-check-list-valid': isMinLengthValid,
        'strong-password-check-list-invalid': !isMinLengthValid
      }"
    >
      <i *ngIf="isMinLengthValid" class="pi pi-check"></i>
      <i *ngIf="!isMinLengthValid" class="pi pi-circle-on"></i>
      8 characters minimum
    </li>
    <li
      [ngClass]="{
        'strong-password-check-list-valid': isContainUppercase,
        'strong-password-check-list-invalid': !isContainUppercase
      }"
    >
      <i *ngIf="isContainUppercase" class="pi pi-check"></i>
      <i *ngIf="!isContainUppercase" class="pi pi-circle-on"></i>
      1 uppercase
    </li>
    <li
      [ngClass]="{
        'strong-password-check-list-valid': isContainLowercase,
        'strong-password-check-list-invalid': !isContainLowercase
      }"
    >
      <i *ngIf="isContainLowercase" class="pi pi-check"></i>
      <i *ngIf="!isContainLowercase" class="pi pi-circle-on"></i>
      1 lowercase
    </li>
    <li
      [ngClass]="{
        'strong-password-check-list-valid': isContainSpecialCharacter,
        'strong-password-check-list-invalid': !isContainSpecialCharacter
      }"
    >
      <i *ngIf="isContainSpecialCharacter" class="pi pi-check"></i>
      <i *ngIf="!isContainSpecialCharacter" class="pi pi-circle-on"></i>
      1 special character
    </li>
    <li
      [ngClass]="{
        'strong-password-check-list-valid': isContainNumber,
        'strong-password-check-list-invalid': !isContainNumber
      }"
    >
      <i *ngIf="isContainNumber" class="pi pi-check"></i>
      <i *ngIf="!isContainNumber" class="pi pi-circle-on"></i>
      1 number
    </li>
  </ul>
</div>
