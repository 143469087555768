import { Injectable, OnDestroy } from '@angular/core';
import {
  Action,
  Select,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth';

export interface ITenant {
  id: string;
  isInternal: boolean;
  isSubscriptionActive: boolean;
  name: string;
  states: string[];
}

export interface IUser {
  firstName: string;
  lastName: string;
  displayName: string;
  initials: string;
  email: string;
  tenantId: string;
  tenantName: string;
  tenant?: ITenant;
  products?: any[];
}

export class UpdateUserAction {
  static readonly type = '[MsAuthService] UpdateUserAction';

  constructor(public user: IUser) {}
}

export interface MsAuthStateModel {
  user: IUser | undefined;
}

@State<MsAuthStateModel>({
  name: 'msAuth',
  defaults: {
    user: undefined,
  },
})
@Injectable()
export class MsAuthState implements OnDestroy {
  private _subscription = new Subscription();

  constructor(private _store: Store, private _authService: AuthService) {
    this.setUpStoreListener();
    this.setUpAuthServiceEventsListener();
  }

  @Selector()
  static user(state: MsAuthStateModel) {
    return state && state.user;
  }

  @Selector()
  static hasOrganizationData(state: MsAuthStateModel) {
    const tenant = state.user?.tenant as any;
    return (
      (state && state.user && tenant?.clientType?.hasOrganizationData) || false
    );
  }

  @Action([UpdateUserAction])
  isAuthenticatedAction(
    ctx: StateContext<MsAuthStateModel>,
    action: UpdateUserAction
  ): void {
    ctx.setState({
      ...ctx.getState(),
      user: action.user,
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private setUpStoreListener(): void {
    const subscription = this._store
      .select(MsAuthState)
      .subscribe((state: MsAuthStateModel | undefined) => {
        console.log('[MsAuthState] store update', state);
      });

    this._subscription.add(subscription);
  }

  private setUpAuthServiceEventsListener(): void {
    const subscription = this._authService.accountProfile$.subscribe((user) => {
      this._store.dispatch(new UpdateUserAction(user));
    });

    this._subscription.add(subscription);
  }
}
