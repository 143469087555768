<div class="container">
  <fa-icon
    *ngIf="sortOrder === 1"
    class="text-primary"
    [icon]="faArrowUp"
  ></fa-icon>
  <fa-icon
    *ngIf="sortOrder === 0"
    class="text-secondary default-sort"
    [icon]="faArrowUp"
  ></fa-icon>
  <fa-icon
    *ngIf="sortOrder === -1"
    [icon]="faArrowDown"
    class="text-primary"
  ></fa-icon>
</div>
<span *ngIf="isMultiSorted()" class="p-sortable-column-badge">{{
  getBadgeValue()
}}</span>
