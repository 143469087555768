import { State, StateContext, Selector } from '@ngxs/store';
import { Injectable, Injector } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { LookupsService } from '../services/lookups.service';
import {
  IBrand,
  IPropertyType,
  IState,
  ILoanType,
  ILoanPurpose,
  ILoanStatus,
  ILoanForecastTimeSeries,
  IBankSize,
  IMSA,
} from '../models';

@Injectable()
export class LookupsStateModel {
  brands: IBrand[];
  propertyDocumentTypes: IPropertyType[];
  marketInsightPropertyTypes: IPropertyType[];
  states: IState[];
  loanTypes: ILoanType[];
  loanPurposes: ILoanPurpose[];
  loanStatuses: ILoanStatus[];
  loanForecastTimeSeries: ILoanForecastTimeSeries[];
  bankSizes: IBankSize[];
  msaNames: IMSA[];
}
@State<LookupsStateModel>({
  name: 'lookups',
  defaults: {
    brands: [],
    propertyDocumentTypes: [],
    marketInsightPropertyTypes: [],
    states: [],
    loanTypes: [],
    loanPurposes: [],
    loanStatuses: [],
    loanForecastTimeSeries: [],
    bankSizes: [],
    msaNames: [],
  },
})
@Injectable()
export class LookupsState {
  private static lookupsService: LookupsService;

  constructor(injector: Injector) {
    LookupsState.lookupsService = injector.get<LookupsService>(LookupsService);
  }

  @Selector()
  static propertyTypes(state: LookupsStateModel) {
    return state.propertyDocumentTypes;
  }

  @Selector()
  static marketInsightPropertyTypes(state: LookupsStateModel) {
    return state.marketInsightPropertyTypes;
  }

  @Selector()
  static states(state: LookupsStateModel) {
    return state.states;
  }

  @Selector()
  static loanTypes(state: LookupsStateModel) {
    return state.loanTypes;
  }

  @Selector()
  static loanPurposes(state: LookupsStateModel) {
    return state.loanPurposes;
  }

  @Selector()
  static loanStatuses(state: LookupsStateModel) {
    return state.loanStatuses;
  }

  @Selector()
  static loanForecastTimeSeries(state: LookupsStateModel) {
    return state.loanForecastTimeSeries;
  }

  @Selector()
  static bankSizes(state: LookupsStateModel) {
    return state.bankSizes;
  }

  @Selector()
  static msaNames(state: LookupsStateModel) {
    return state.msaNames;
  }

  @Receiver({ type: '[Lookups] Lookups Load' })
  public static load(
    context: StateContext<LookupsStateModel>,
    action: EmitterAction<void>
  ) {
    return this.lookupsService.load().pipe(
      tap((lookups) => {
        const mixedTypeId = 4;
        lookups.propertyDocumentTypes.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        context.patchState({
          brands: lookups.brands,
          propertyDocumentTypes: lookups.propertyDocumentTypes,
          marketInsightPropertyTypes: lookups.marketInsightPropertyTypes.filter(
            (type) => type.id !== mixedTypeId
          ),
          states: lookups.states,
          loanTypes: lookups.loanTypes,
          loanPurposes: lookups.loanPurposes,
          loanStatuses: lookups.loanStatuses,
          loanForecastTimeSeries: lookups.loanForecastTimeSeries,
          bankSizes: lookups.bankSizes,
          msaNames: lookups.msa,
        });
      }),
      catchError((response) => throwError(response.error))
    );
  }
}
