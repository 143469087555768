import { ApiSearchFilterOperator, ApiSearchFilterPropertyType } from './models';

export const FilterNameSymbol = Symbol('Filter name symbol');

export function stringContainsIgnoreCaseFilter(
  value: string,
  property: string
) {
  return {
    property: `${property}.toUpper().contains(@@)`,
    value: value.toUpperCase(),
    propertyType: ApiSearchFilterPropertyType.String,
    operator: ApiSearchFilterOperator.Raw,
  };
}
