import { NgxsModuleOptions } from '@ngxs/store';
import { StateClass } from '@ngxs/store/internals';

import { environment } from '../environments/environment';

export const ngxsStates: StateClass[] = [];

export const ngxsOptions: NgxsModuleOptions = {
  developmentMode: !environment.production,
};
