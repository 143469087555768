import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export namespace MsDateValidators {
  export function future(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const dateString: string = c?.value;
      return new Date(dateString) > new Date()
        ? null
        : {
            dateNotInFuture: { valid: false },
          };
    };
  }
}
