<div class="cover">
  <div class="ms-container flex align-items-center flex-column">
    <div class="medallion my-5 flex" *ngIf="appSlug">
      <ms-logo class="w-5rem" type="medallion" color="white"></ms-logo>

      <div class="title text-white flex align-items-center">
        <h1 class="my-0 ml-3 w-12rem font-normal">
          MountainSeed {{ applicationName }}
        </h1>
      </div>
    </div>

    <div class="text-center logo my-5" *ngIf="!appSlug">
      <ms-logo type="range" color="white"></ms-logo>
    </div>

    <div class="card">
      <div
        class="
          flex flex-wrap
          align-items-center
          justify-content-center
          card-container
          blue-container
        "
      >
        <div class="border-round w-27rem p-3 m-3">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
