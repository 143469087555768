import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { MsAuthState } from '@ms/angular-workspace/dist/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class MiPasswordExpiredGuard implements CanActivate, CanActivateChild {
  private _userProfile$: Observable<any> = inject(Store).select(
    MsAuthState.user
  );

  canActivate() {
    return this.redirectIfPasswordExpired();
  }
  canActivateChild() {
    return this.redirectIfPasswordExpired();
  }

  private redirectIfPasswordExpired() {
    return this._userProfile$.pipe(filter((user) => !!user)).pipe(
      map(
        (claims) =>
          !(
            new Date(claims.passwordExpiration).getTime() < new Date().getTime()
          )
      ),
      tap((isPasswordExpired) => {
        if (!isPasswordExpired) {
          window.location.href =
            environment.productUrls.authUrl + '/secure/password-expired';
        }
      })
    );
  }
}
