import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsDateRangePickerComponent } from './ms-date-range-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { DateMaskDirective } from './date-mask.directive';

@NgModule({
  declarations: [MsDateRangePickerComponent, DateMaskDirective],
  imports: [CommonModule, CalendarModule, FontAwesomeModule, FormsModule],
  exports: [MsDateRangePickerComponent],
})
export class MsDateRangePickerModule {}
