import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { TopNavigationBarModule } from '../top-navigation-bar';
import { ApplicationShellComponent } from './application-shell.component';
import { MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [ApplicationShellComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    //
    AvatarModule,
    ButtonModule,
    MenuModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    DividerModule,
    BadgeModule,
    //
    TopNavigationBarModule,
  ],
  providers: [MessageService],
  exports: [ApplicationShellComponent],
})
export class ApplicationShellModule {}
