import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Injector,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'ms-currency-input',
  templateUrl: './ms-currency-input.component.html',
  styleUrls: ['./ms-currency-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MsCurrencyInputComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsCurrencyInputComponent extends ControlValueAccessorConnector {
  @Input() currency: string;
  @Input() styleClass: string;
  @Input() placeholder: string;
  @Input() minFractionDigits: number = 0;
  @Input() maxLength: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
