import {
  Component,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { Message } from 'primeng/api';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const DRAG_AREA = 'dragarea';
const DROP_AREA = 'droparea';

@Component({
  selector: 'ms-file-upload',
  templateUrl: './ms-file-upload.component.html',
  styleUrls: ['./ms-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MsFileUploadComponent,
      multi: true,
    },
  ],
})
export class MsFileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() fileTypes: string[] = [];
  @Input() allowMultiple: boolean;
  @Output() select = new EventEmitter<File[]>();
  @Input() serverErrorMessages: Message[] | null;
  @Input() isLoading: boolean | null;
  @Input() formControlName: string;
  @Input() mainLabel: string;
  @Input() selectedFilesOnTop: boolean;
  dragAreaClass: string;
  files: File[] = [];
  filename: string = '';
  disabled: boolean;
  touched: boolean;
  onChange: Function = () => {};
  onTouched: Function = () => {};
  onValidatorChange: Function = () => {};

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    this.dragAreaClass = DROP_AREA;
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
    this.dragAreaClass = DROP_AREA;
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: DragEvent) {
    this.dragAreaClass = DRAG_AREA;
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    this.dragAreaClass = DRAG_AREA;
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    this.dragAreaClass = DRAG_AREA;
    event.preventDefault();
    event.stopPropagation();
    this.markAsTouched();
    const { dataTransfer } = event;
    if (dataTransfer?.files) {
      this.addFiles(Array.from(dataTransfer?.files));
    }
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  ngOnInit() {
    this.dragAreaClass = DRAG_AREA;
  }

  writeValue(value: null): void {
    this.host.nativeElement.value = '';
    this.files = [];
  }

  registerOnChange(onChange: Function): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = !this.disabled;
  }

  registerOnValidatorChange(onValidatorChange: () => void) {
    this.onValidatorChange = onValidatorChange;
  }

  onClick(fileUpload: HTMLInputElement) {
    this.markAsTouched();
    fileUpload.click();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onFileChange(event: Event) {
    const element = event.target as HTMLInputElement;
    const { files } = element;
    if (files) {
      this.addFiles(Array.from(files));
    }
  }

  addFiles(files: File[]) {
    this.files.push(...files);
    this.select.next(this.files);
    this.onChange(this.files);
  }

  remove(file: File) {
    const index: number = this.files.findIndex(
      (f) => f.name === file.name && f.size === file.size
    );
    if (index > -1) {
      this.files.splice(index, 1);
      this.select.next(this.files);
      this.onChange(this.files);
    }
  }

  hasExtension(file: File, extension: string) {
    return file.name.endsWith(extension);
  }

  typesToString(separator: string): string {
    return this.fileTypes.join(separator);
  }
}
