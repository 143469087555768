import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { emailMaxLength, emailValidatorRegexp } from './constants';

export enum MinMaxCompareOptions {
  GreaterThen,
  LessThen,
}

export enum BeforeAfterCompareOptions {
  Before,
  After,
}

export class MsValidators {
  static email(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    const responseOk = null;

    if (!email) {
      return responseOk;
    }

    if (email.length > emailMaxLength) {
      return { maxlength: true };
    }

    if (email !== '' && !emailValidatorRegexp.test(email)) {
      return { email: true };
    }

    return null;
  }

  static minMax(
    compareToControl: string,
    compareOption: MinMaxCompareOptions
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let originalValue: number = +control.value;
      let compareToValue: number =
        +control.parent?.get(compareToControl)?.value;

      if (!originalValue || !compareToValue) {
        return null;
      }

      if (
        compareOption === MinMaxCompareOptions.GreaterThen &&
        originalValue < compareToValue
      ) {
        return { minMax: true };
      }

      if (
        compareOption === MinMaxCompareOptions.LessThen &&
        originalValue > compareToValue
      ) {
        return { minMax: true };
      }
      control.parent?.get(compareToControl)?.markAsPristine();
      return null;
    };
  }

  static beforeAfter(
    compareToControl: string,
    compareOption: BeforeAfterCompareOptions
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let originalTimestamp = Date.parse(control.value);
      let compareToTimestamp = Date.parse(
        control.parent?.get(compareToControl)?.value
      );

      if (isNaN(originalTimestamp) || isNaN(originalTimestamp)) {
        return null;
      }

      let originalValue: Date = new Date(originalTimestamp);
      let compareToValue: Date = new Date(compareToTimestamp);

      if (!originalValue || !compareToValue) {
        return null;
      }

      if (
        compareOption === BeforeAfterCompareOptions.After &&
        originalValue < compareToValue
      ) {
        return { beforeAfter: true };
      }

      if (
        compareOption === BeforeAfterCompareOptions.Before &&
        originalValue > compareToValue
      ) {
        return { beforeAfter: true };
      }
      control.parent?.get(compareToControl)?.markAsPristine();
      return null;
    };
  }
}
