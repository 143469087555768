<p-toast>
  <ng-template let-message pTemplate="message">
    <span
      [class]="
        'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')
      "
      [ngClass]="{
        'pi-info-circle': message.severity === 'info' || 'tutorial',
        'pi-exclamation-triangle': message.severity === 'warn',
        'pi-times-circle': message.severity === 'error',
        'pi-check': message.severity === 'success'
      }"
    ></span>
    <div class="p-toast-message-text">
      <div class="p-toast-summary" *ngIf="!!message.summary">
        {{ message.summary }}
      </div>
      <div
        class="p-toast-detail"
        [innerHtml]="message.detail"
        [ngClass]="{ 'mt-0': !message.summary }"
      ></div>
    </div>
  </ng-template>
</p-toast>

<p-toast key="bottom-left" position="bottom-left">
  <ng-template let-message pTemplate="message">
    <span
      [class]="
        'p-toast-tutorial-message-icon pi' +
        (message.icon ? ' ' + message.icon : '')
      "
      [ngClass]="{
        'pi-info-circle': message.severity === 'info' || 'tutorial',
        'pi-exclamation-triangle': message.severity === 'warn',
        'pi-times-circle': message.severity === 'error',
        'pi-check': message.severity === 'success'
      }"
    ></span>
    <div
      class="p-toast-message-text"
      [ngClass]="
        message.severity === 'tutorial' ? 'p-toast-tutorial-message-text' : ''
      "
    >
      <div class="p-toast-summary" *ngIf="!!message.summary">
        {{ message.summary }}
      </div>
      <div
        class="p-toast-detail"
        [innerHtml]="message.detail"
        [ngClass]="{ 'mt-0': !message.summary }"
      ></div>
    </div>
  </ng-template>
</p-toast>

<p-confirmDialog #cd>
  <ng-template pTemplate="header">
    <h3>Confirmation</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      class="p-button-outlined"
      pButton
      type="button"
      label="Cancel"
      (click)="cd.reject()"
    ></button>
    <button pButton type="button" label="Delete" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

<router-outlet></router-outlet>
