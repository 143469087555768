import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeSelectModule } from 'primeng/treeselect';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { CalendarModule } from 'primeng/calendar';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { MsFileUploadComponent } from './file-upload/ms-file-upload.component';
import { MsLogoModule } from './ms-logo';
import { MsLoaderComponent } from './loader/ms-loader.component';
import { StrongPasswordCheckComponent } from './strong-password-check/strong-password-check.component';
import { TopNavigationBarModule } from './top-navigation-bar';
import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { MsDialogComponent } from './dialog-modal/ms-dialog.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { MsDropDownComponent } from './drop-down';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LayoutComponent } from './layout/layout.component';
import { MsFileListItemComponent } from './file-list-item/ms-file-list-item.component';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { FormatPhoneNumber } from './pipes/format-phone-number.pipe';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { ToStringDatePipe } from './pipes/to-string-date.pipe';
import { ToTitleCasePipe } from './pipes/to-title-case.pipe';
import { ConcatPipe } from './pipes/concat.pipe';
import { ParseFloatFromCurrencyPipe } from './pipes/parse-float-from-currency.pipe';
import { MsCurrencyInputComponent } from './currency-input/ms-currency-input.component';
import { MsPercentageInputComponent } from './percentage-input/ms-percentage-input.component';
import { ControlValueAccessorConnector } from './control-value-accessor-connector';
import { MsSidebarMenuModule } from './sidebar-menu';
import { ChipsModule } from 'primeng/chips';
import { MsRadioButtonsComponent } from './radio-buttons/ms-radio-buttons.component';
import { TrimValueDirective } from './trim-value.directive';
import { MsModalModule } from './modal';
import { MsDateRangePickerModule } from './date-range-picker/ms-date-range-picker.module';
import { JoinPipe } from './pipes/join.pipe';

@NgModule({
  declarations: [
    FormatPhoneNumber,
    FormatBytesPipe,
    SplitStringPipe,
    JoinPipe,
    ToStringDatePipe,
    ToTitleCasePipe,
    ConcatPipe,
    ParseFloatFromCurrencyPipe,
    LayoutComponent,
    MsDialogComponent,
    MsDropDownComponent,
    MsFileListItemComponent,
    MsFileUploadComponent,
    MsLoaderComponent,
    SearchInputComponent,
    StrongPasswordCheckComponent,
    ValidationErrorsComponent,
    MsCurrencyInputComponent,
    MsPercentageInputComponent,
    ControlValueAccessorConnector,
    MsRadioButtonsComponent,
    TrimValueDirective,
    MsDialogComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // PrimNg modules
    AutoCompleteModule,
    AvatarModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    FileUploadModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    TreeSelectModule,
    OverlayPanelModule,
    PasswordModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    SkeletonModule,
    TabMenuModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TriStateCheckboxModule,
    ChipsModule,
    // FontAwesome
    FontAwesomeModule,
    // Internal modules
    MsLogoModule,
    TopNavigationBarModule,
    MsSidebarMenuModule,
    MsModalModule,
    MsDateRangePickerModule,
  ],
  exports: [
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    TrimValueDirective,
    // PrimNg modules
    AvatarModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    FileUploadModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    TreeSelectModule,
    OverlayPanelModule,
    PasswordModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    SkeletonModule,
    TabMenuModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TriStateCheckboxModule,
    ChipsModule,
    // Internal Components
    LayoutComponent,
    MsDialogComponent,
    MsDropDownComponent,
    MsFileListItemComponent,
    MsFileUploadComponent,
    MsLoaderComponent,
    SearchInputComponent,
    StrongPasswordCheckComponent,
    ValidationErrorsComponent,
    MsCurrencyInputComponent,
    MsPercentageInputComponent,
    MsRadioButtonsComponent,
    //Internal Pipes
    FormatBytesPipe,
    FormatPhoneNumber,
    SplitStringPipe,
    JoinPipe,
    ToStringDatePipe,
    ToTitleCasePipe,
    ConcatPipe,
    ParseFloatFromCurrencyPipe,
    // FontAwesome
    FontAwesomeModule,
    // Internal modules
    MsLogoModule,
    TopNavigationBarModule,
    MsSidebarMenuModule,
    TrimValueDirective,
    MsModalModule,
    MsDateRangePickerModule,
  ],
})
export class MsComponentsModule {}
