import { Component, Input } from '@angular/core';

@Component({
  selector: 'ms-loader',
  templateUrl: './ms-loader.component.html',
  styleUrls: ['./ms-loader.component.scss'],
})
export class MsLoaderComponent {
  @Input() isLocal: boolean = false;
  constructor() {}
}
