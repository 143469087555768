import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

export interface IFile {
  blobId: number;
  fileName?: string;
  mimeType?: string;
}

@Component({
  selector: 'ms-file-list-item',
  templateUrl: './ms-file-list-item.component.html',
  styleUrls: ['./ms-file-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsFileListItemComponent implements OnInit {
  @Input() file?: IFile;
  @Input() readonly: boolean;
  @Output() delete = new EventEmitter<IFile>();

  constructor() {}

  ngOnInit(): void {}

  onDelete(file: IFile) {
    this.delete.next(file);
  }
}
