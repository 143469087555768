import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { MsAuthState } from './state';

@NgModule({
  imports: [NgxsModule.forFeature([MsAuthState])],
})
export class MsAuthNgxsStoreModule {
  static forRoot(): ModuleWithProviders<MsAuthNgxsStoreModule> {
    return {
      ngModule: MsAuthNgxsStoreModule,
    };
  }
}
