import { HttpClient } from '@angular/common/http';
import { Optional } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  ApiCreateRequestBody,
  ApiFilter,
  ApiGetRequestParams,
  ApiPatchRequestBody,
  ApiSearchRequestBody,
  ApiSearchResponse,
  ApiUpdateRequestBody,
  IEntityBase,
} from './models';

export type ApiRequestBody<T> =
  | ApiPatchRequestBody<T>
  | ApiCreateRequestBody<T>
  | ApiUpdateRequestBody<T>
  | ApiSearchRequestBody;

export abstract class FeatureApiClientBase<T extends IEntityBase> {
  entityEndpoint: string;
  protected readonly apiPathPrefix = '/api/v1';

  private readonly requestSubject$ = new BehaviorSubject('');
  events$ = this.requestSubject$.asObservable();

  protected constructor(
    private http: HttpClient,
    @Optional() entityEndpoint = ''
  ) {
    this.entityEndpoint = entityEndpoint;
  }

  search(body: ApiSearchRequestBody): Observable<ApiSearchResponse<T>> {
    return this.request('POST', this.url() + '/search', { body });
  }

  get(id: number, params: ApiGetRequestParams = {}): Observable<T> {
    return this.request('GET', this.url(id), { params }).pipe(
      map((r: any) => r.data)
    );
  }

  create(entity: T): Observable<T> {
    const body = { currentItem: entity };
    return this.request('POST', this.url(), { body }).pipe(
      map((r: any) => r.data)
    );
  }

  update(entity: T, originalEntity: T): Observable<T> {
    const body = <ApiUpdateRequestBody<T>>{
      currentItem: entity,
      originalItem: originalEntity,
    };

    return this.request('PUT', this.url(entity.id), { body }).pipe(
      switchMap(() => this.get(entity.id))
    );
  }

  patch(
    entity: Partial<T>,
    propertiesToUpdate: string[],
    url?: string,
    extendBody: any = {}
  ): Observable<T> {
    const body = <ApiPatchRequestBody<T>>{
      currentItem: entity,
      propertiesToUpdate: propertiesToUpdate,
      ...extendBody,
    };
    return this.request('PATCH', url || this.url(entity.id), { body }).pipe(
      switchMap(() => this.get(entity.id as any))
    );
  }

  delete(id: number): Observable<boolean> {
    return this.request('DELETE', this.url(id)).pipe(map(() => true));
  }

  getAll(filters?: ApiFilter[]): Observable<ApiSearchResponse<T>> {
    return this.search({
      currentPage: 0,
      pageSize: -1,
      filters: filters || [],
    });
  }

  request<R>(
    method: string,
    url: string,
    options?: { body?: ApiRequestBody<T>; params?: ApiGetRequestParams }
  ) {
    this.emitRequestStatus('Start');

    return this.http
      .request<R>(method, url, {
        params: { ...options?.params },
        body: { ...options?.body },
      })
      .pipe(
        tap((data) => this.emitRequestStatus('Success', data)),
        catchError((err) => {
          this.emitRequestStatus('Error', err);
          return throwError(err);
        })
      );
  }

  url(path: string | number = '') {
    if (path) {
      path = `/${path}`;
    }

    return `${this.apiPathPrefix}/${this.entityEndpoint}${path}`;
  }

  public formatDateToString(date: Date): string | undefined {
    if (!date) {
      return undefined;
    } else {
      const utcDate = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      );
      return [
        utcDate.getFullYear(),
        (utcDate.getMonth() + 1).toString().padStart(2, '0'),
        utcDate.getDate().toString().padStart(2, '0'),
      ].join('-');
    }
  }

  private emitRequestStatus(name: string, data?: any) {
    console.log(`[MS Api][${name}] ${this.entityEndpoint.toUpperCase()}`, data);
    return this.requestSubject$.next(
      `[MS Api][${name}] ${this.entityEndpoint.toUpperCase()}`
    );
  }

  reInviteAppraiser(id: number): Observable<any> {
    return this.http.post(`${this.url()}/re-invite/${id}`, {});
  }
}
