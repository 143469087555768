<p-toast [style]="{ marginTop: '5px' }"></p-toast>

<!-- Frontend Application Container -->
<div
  class="h-screen"
  [gdAreas]="gdAreas"
  gdRows="var(--ms-navbar-height)"
  [gdColumns]="gdColumns"
>
  <!-- Header and Top Navigation Bar -->
  <header role="banner" class="ms__header fixed w-full" gdArea="ms-header">
    <ms-top-navigation-bar
      [appName]="appName"
      [initials]="userProfile?.initials"
      [products]="userProfile?.products"
      [items]="mainNav"
      [userMenuItems]="userMenu"
    ></ms-top-navigation-bar>
  </header>

  <div gdArea="ms-content" class="ms__main overflow-x-auto p-5 bg-white">
    <!-- Sidebar -->
    <div
      *ngIf="showSidebar"
      class="ms__sidebar fixed left-0 surface-ground"
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseLeave()"
      [ngStyle]="{ width: sidebarWidth }"
    >
      <div
        [style.visibility]="
          sidebarOverlayExpanded || sidebarExpanded ? 'visible' : 'hidden'
        "
      >
        <!-- Sidebar Head -->
        <div class="py-3">
          <button
            pButton
            type="button"
            label="Bank of America"
            iconPos="right"
            class="ml-3 p-button-text font-bold"
            icon="pi pi-angle-down"
          >
            <p-avatar
              class="mr-3"
              shape="circle"
              styleClass="text-white bg-pink-500"
              icon="pi pi-briefcase"
            ></p-avatar>
          </button>
          <p-divider styleClass="w-10 my-2 mx-auto"></p-divider>
        </div>

        <!-- Sidebar Menu / Main content -->
        <div class="ms__sidebar_nav pb-3">
          <p-menu [model]="sidebarNav" styleClass="w-full sidebar-nav"></p-menu>
          <ng-container *ngFor="let item of sidebarNav; index as i">
            <p-badge
              *ngIf="item.badge"
              [value]="item.badge"
              class="menu-badge {{ 'menu-badge-' + i }}"
            ></p-badge>
          </ng-container>
        </div>
      </div>

      <!-- Sidebar Toggle Button -->
      <button
        pButton
        type="button"
        class="
          ms__sidebar_toggle
          p-button-outlined p-button-rounded
          absolute
          p-button-sm
          bg-white
        "
        [icon]="sidebarExpanded ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"
        (click)="toggleSidebar()"
      ></button>
    </div>

    <!-- Page Content -->
    <ng-content></ng-content>

    <!-- <ms-loader style="z-index: 10000;" *ngIf="(userProfile$ | async) === null"></ms-loader> -->
  </div>
</div>
