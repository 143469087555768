export enum ApiSearchSortDirections {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum ApiSearchIncludeType {
  None = 'none',
  Slim = 'slim',
  Full = 'full',
}

export enum ApiSearchFilterOperator {
  Equal = 'eq',
  NotEqual = 'neq',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  Contains = 'contains',
  DoesNotContain = 'not contains',
  Raw = 'raw',
}

export enum ApiSearchFilterPropertyType {
  String = 'string',
  Integer = 'int',
  Date = 'date',
  List = 'list',
  Boolean = 'boolean',
}

export interface ApiFilter {
  property: string;
  value?: string | boolean | number;
  operator?: ApiSearchFilterOperator;
  propertyType?: ApiSearchFilterPropertyType;
  orFilters?: ApiFilter[];
  andFilters?: ApiFilter[];
}

export interface ApiSearchRequestBody {
  currentPage: number;
  pageSize: number;
  includes?: ApiSearchIncludeType;
  sortDirection?: ApiSearchSortDirections;
  sortField?: string;
  filters: ApiFilter[];
  secondaryRequest?: {
    sortDirection?: ApiSearchSortDirections;
    sortField?: string;
  };
}

export interface ApiGetResponseMessage {
  code: number;
  message: string;
}

export interface ApiGetResponse<T> {
  data: T;
  messages: ApiGetResponseMessage[];
}

export interface ApiSearchResponseData<T> {
  items: T[];
  totalItemCount: number;
}

export interface ApiSearchResponseTableData<T>
  extends ApiSearchResponseData<T> {
  itemCount?: number;
  sortField?: string | undefined;
  sortDirection?: ApiSearchSortDirections;
}

export interface ApiSearchResponse<T>
  extends ApiGetResponse<ApiSearchResponseData<T>> {}

export interface ApiGetRequestParams {
  includes?: ApiSearchIncludeType;
}

export interface IEntityBase {
  id: number;
  rowVersion: string;
  name?: string | any;
  createdDate?: string;
  lastModifiedDate?: string;
}

//

export interface ApiCommandRequestBody<T> {
  currentItem: T;
  originalItem?: T;
}

export interface ApiCreateRequestBody<T> extends ApiCommandRequestBody<T> {}

export interface ApiUpdateRequestBody<T> extends ApiCommandRequestBody<T> {}

export interface ApiPatchRequestBody<T> extends ApiCommandRequestBody<T> {
  propertiesToUpdate: string[];
}
