import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ms-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input() appSlug: string | null = null;

  constructor(private route: ActivatedRoute) {}

  public get applicationName() {
    // TODO: app name should be taken from global config and shared between apps
    if (!this.appSlug) {
      return '';
    }

    const apps: { [key: string]: string } = {
      mi: 'Analytics',
      eltto: 'Eltto',
    };

    return apps[this.appSlug.toLowerCase()];
  }

  ngOnInit(): void {
    this.appSlug = this.appSlug || this.route.snapshot.queryParamMap.get('app');
  }
}
