<svg
  *ngIf="type === 'range'"
  [style]="'fill: ' + _color"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 144.27 45.91"
  style="enable-background: new 0 0 144.27 45.91"
  xml:space="preserve"
>
  <g>
    <path
      d="M68.22,7.98l4.69,6.18c1.05-0.85,2.04-1.64,3.03-2.45c3.42-2.79,6.79-5.63,10.27-8.35c1.01-0.79,2.29-1.24,3.48-1.77
		c1.27-0.57,2.57-1.05,3.9-1.59c0.38,0.54,0.72,1.01,1.05,1.49c2.94,4.35,5.88,8.69,8.8,13.06c0.47,0.7,0.99,1.03,1.85,1
		c1.59-0.06,3.19-0.06,4.78,0.01c0.45,0.02,1.01,0.21,1.33,0.52c3.34,3.26,6.64,6.56,9.94,9.85c0.17,0.17,0.31,0.37,0.51,0.62
		c-0.19,0.06-0.29,0.12-0.38,0.12c-2.32,0.01-4.64,0-6.96,0.01c-0.6,0-0.89-0.35-1.13-0.83c-1.11-2.22-2.23-4.44-3.35-6.65
		c-0.16-0.32-0.37-0.61-0.52-0.86c-3.09-0.33-5.93,0.21-8.69,1.36c-2.91,1.21-5.87,2.32-8.82,3.41c-0.45,0.17-1.08,0.18-1.52,0
		c-1.84-0.73-3.64-1.55-5.46-2.34c-0.1-0.04-0.18-0.11-0.35-0.22l7.44-16.79c-2.96,0.26-5.31,1.57-7.44,3.35
		c-6.56,5.52-20.31,17.08-20.76,17.44c-0.34-1.73-0.65-3.3-0.96-4.87c-0.13-0.63-0.24-1.26-0.4-1.88c-0.27-1.05,0-1.98,0.58-2.86
		c0.75-1.14,1.48-2.29,2.16-3.52c-1.32,0.22-2.57,0.6-3.71,1.27c-5.01,2.93-17,9.99-17.99,10.57c-0.25,0.14-0.52,0.25-0.78,0.37
		l-0.11-0.16l2.38-2.57c-2.47-0.29-4.72-0.07-6.93,0.79c-4.37,1.71-8.76,3.36-13.14,5.03c-0.27,0.1-0.56,0.18-0.84,0.27l-0.08-0.19
		c0.28-0.14,0.55-0.3,0.83-0.43c4.97-2.36,9.93-4.73,14.91-7.06c0.54-0.25,1.17-0.38,1.77-0.4c1.56-0.06,3.13,0.04,4.7-0.05
		c0.69-0.04,1.45-0.22,2.04-0.57c3.36-2,6.73-3.98,10-6.13c2.82-1.86,5.98-2.85,9.07-4.06C67.63,8,67.92,8.02,68.22,7.98z"
    />
    <g>
      <polygon
        points="19.18,45.64 15.87,45.64 13.65,36.8 11.04,45.64 8.14,45.64 5.53,36.61 3.12,45.64 0,45.64 3.95,30.84
			6.72,30.84 9.58,39.84 12.46,30.84 15.23,30.84 		"
      />
      <path
        d="M31.19,43.58c-1.5,1.5-3.27,2.26-5.32,2.26c-2.04,0-3.82-0.76-5.34-2.26c-1.5-1.52-2.26-3.29-2.26-5.34
			s0.76-3.82,2.26-5.32c1.52-1.52,3.29-2.28,5.34-2.28c2.04,0,3.82,0.76,5.32,2.28c1.52,1.5,2.28,3.27,2.28,5.32
			S32.71,42.06,31.19,43.58z M29.29,34.83c-0.97-0.97-2.1-1.46-3.41-1.46c-1.3,0-2.43,0.49-3.43,1.46c-0.97,0.97-1.46,2.1-1.46,3.41
			s0.49,2.43,1.46,3.43c0.99,0.97,2.12,1.46,3.43,1.46c1.3,0,2.43-0.49,3.41-1.46c0.97-0.99,1.46-2.12,1.46-3.43
			S30.26,35.81,29.29,34.83z"
      />
      <path
        d="M45,40.57c0,1.03-0.24,1.95-0.72,2.76c-0.48,0.81-1.13,1.45-1.94,1.9s-1.71,0.68-2.7,0.68c-0.97,0-1.87-0.23-2.68-0.68
			c-0.81-0.45-1.46-1.08-1.94-1.9c-0.48-0.81-0.72-1.74-0.72-2.76v-9.83h2.92v9.78c0,0.74,0.23,1.34,0.7,1.8
			c0.47,0.47,1.04,0.7,1.72,0.7c0.7,0,1.28-0.23,1.74-0.7c0.47-0.47,0.7-1.07,0.7-1.8v-9.78H45V40.57z"
      />
      <polygon
        points="58.49,30.75 58.49,45.64 56.18,45.64 48.83,36.32 48.83,45.64 45.91,45.64 45.91,30.75 48.33,30.75 55.57,39.93
			55.57,30.75 		"
      />
      <polygon
        points="67.87,33.61 64.93,33.61 64.93,45.64 62.16,45.64 62.16,33.61 59.41,33.61 59.41,30.84 67.87,30.84 		"
      />
      <path
        d="M77.87,45.64l-1.26-3.3h-5.78l-1.26,3.3h-3.36l5.84-14.56h3.3l5.84,14.56H77.87z M71.87,39.6h3.67l-1.84-4.79L71.87,39.6z
			"
      />
      <rect x="82.48" y="30.75" width="2.77" height="14.9" />
      <polygon
        points="99.52,30.75 99.52,45.64 97.2,45.64 89.86,36.32 89.86,45.64 86.94,45.64 86.94,30.75 89.36,30.75 96.6,39.93
			96.6,30.75 		"
      />
      <path
        d="M106.5,32.46c-0.53-0.32-1.05-0.48-1.56-0.48c-0.67,0-1.22,0.17-1.66,0.51c-0.44,0.34-0.66,0.81-0.66,1.41
			c0,0.43,0.14,0.81,0.41,1.14c0.28,0.33,0.67,0.66,1.18,1.01l2.81,1.94c0.88,0.63,1.53,1.22,1.94,1.78
			c0.42,0.56,0.63,1.28,0.63,2.14c0,0.78-0.2,1.47-0.6,2.08c-0.4,0.6-0.93,1.07-1.59,1.41c-0.67,0.33-1.39,0.5-2.17,0.5
			c-0.86,0-1.66-0.21-2.39-0.63c-0.73-0.42-1.49-1.04-2.28-1.88l1.11-1.04c0.6,0.67,1.17,1.18,1.73,1.53
			c0.56,0.35,1.17,0.53,1.82,0.53c0.79,0,1.45-0.22,1.96-0.67c0.51-0.45,0.77-1.04,0.77-1.79c0-0.54-0.16-1-0.47-1.39
			c-0.31-0.38-0.74-0.77-1.3-1.16l-2.76-1.94c-0.77-0.54-1.36-1.08-1.77-1.62c-0.42-0.54-0.63-1.17-0.63-1.91
			c0-0.65,0.17-1.24,0.51-1.76c0.34-0.52,0.81-0.93,1.4-1.23c0.6-0.3,1.26-0.45,2-0.45c0.79,0,1.53,0.19,2.21,0.58
			c0.68,0.39,1.42,1.02,2.23,1.88l-1.06,1C107.63,33.28,107.03,32.78,106.5,32.46z"
      />
      <polygon
        points="112.33,36.88 119.63,36.88 119.63,38.36 112.33,38.36 112.33,44.16 119.63,44.16 119.63,45.64 110.76,45.64
			110.76,30.75 119.63,30.75 119.63,32.23 112.33,32.23 		"
      />
      <polygon
        points="122.51,36.88 129.81,36.88 129.81,38.36 122.51,38.36 122.51,44.16 129.81,44.16 129.81,45.64 120.94,45.64
			120.94,30.75 129.81,30.75 129.81,32.23 122.51,32.23 		"
      />
      <path
        d="M140.16,31.59c1.23,0.56,2.22,1.4,2.98,2.52c0.76,1.12,1.14,2.48,1.14,4.08c0,1.6-0.38,2.96-1.14,4.08
			c-0.76,1.12-1.75,1.96-2.98,2.52c-1.23,0.56-2.55,0.84-3.96,0.84h-4.89v-14.9h4.89C137.61,30.75,138.93,31.03,140.16,31.59z
			 M141.76,34.96c-0.57-0.9-1.34-1.59-2.31-2.06c-0.97-0.47-2.06-0.7-3.26-0.7h-3.32v11.98h3.32c1.2,0,2.29-0.23,3.26-0.7
			c0.97-0.47,1.74-1.15,2.31-2.05c0.57-0.9,0.85-1.98,0.85-3.23C142.61,36.94,142.33,35.86,141.76,34.96z"
      />
    </g>
  </g>
</svg>

<div *ngIf="type === 'medallion'">
  <div>
    <svg
      [style]="'fill: ' + _color"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 79.54 79.54"
      style="enable-background: new 0 0 79.54 79.54"
      xml:space="preserve"
    >
      <g>
        <path
          d="M39.78,0c-0.13,0-0.26,0-0.39,0C17.43,0.21-0.21,18.18,0,40.15c0.01,1.21,0.08,2.41,0.2,3.6c0.03,0.34,0.07,0.67,0.11,1.01
		C2.77,64.4,19.53,79.54,39.76,79.54c0.13,0,0.26,0,0.39,0c19.24-0.18,35.15-14,38.67-32.18c0.27-1.37,0.46-2.77,0.58-4.19
		c0.1-1.24,0.16-2.5,0.14-3.77C79.33,17.56,61.56,0,39.78,0z M66.15,65.65c-6.91,7.05-16.16,10.98-26.03,11.07
		c-0.12,0-0.24,0-0.36,0c-9.8,0-19.03-3.8-26-10.69C7.63,59.96,3.88,52.1,3.01,43.62c1-0.42,2-0.85,3.01-1.28
		c2.03-0.87,4.11-1.09,6.38-0.8l-2.18,2.59l0.1,0.16c0.24-0.12,0.49-0.23,0.72-0.37c0.91-0.58,11.93-7.7,16.54-10.65
		c1.05-0.67,2.19-1.06,3.41-1.28c-0.63,1.24-1.3,2.4-1.99,3.55c-0.53,0.89-0.78,1.82-0.53,2.88c0.15,0.62,0.26,1.26,0.37,1.89
		c0.29,1.58,0.57,3.17,0.89,4.91c0.41-0.36,0.72-0.61,1-0.87l18.09-16.71c1.95-1.8,4.12-3.12,6.84-3.38l-6.84,16.93
		c0.16,0.11,0.23,0.18,0.32,0.23c1.67,0.8,3.33,1.63,5.02,2.36c0.41,0.18,0.98,0.17,1.4,0c2.72-1.1,5.44-2.22,8.12-3.44
		c2.54-1.16,5.15-1.71,7.99-1.37c0.14,0.25,0.33,0.54,0.48,0.86c1.03,2.23,2.06,4.47,3.08,6.71c0.18,0.39,0.38,0.69,0.74,0.8
		C74.54,54.19,71.18,60.52,66.15,65.65z M73.4,36.67c-0.29-0.31-0.8-0.51-1.22-0.53c-1.46-0.06-2.93-0.07-4.4-0.01
		c-0.79,0.03-1.27-0.3-1.7-1.01c-2.68-4.4-8.71-14.12-9.05-14.66c-1.22,0.54-2.43,1.03-3.59,1.6c-1.09,0.54-2.28,0.99-3.2,1.78
		c-3.2,2.73-6.3,5.6-9.45,8.42c-0.91,0.81-1.82,1.61-2.78,2.47l-4.31-6.23c-0.28,0.04-0.55,0.02-0.77,0.12
		c-2.84,1.22-5.75,2.23-8.34,4.1c-3,2.17-6.1,4.17-9.19,6.18c-0.54,0.35-1.24,0.54-1.87,0.58c-1.44,0.08-2.88-0.01-4.32,0.05
		c-0.55,0.02-1.13,0.15-1.63,0.41c-1.55,0.8-3.1,1.6-4.66,2.41c-0.05-0.74-0.08-1.48-0.09-2.22c-0.09-9.87,3.66-19.19,10.58-26.24
		C20.3,6.84,29.54,2.9,39.42,2.81c0.12,0,0.36,0,0.36,0c9.8,0,19.03,3.8,26,10.7c6.97,6.9,10.86,16.1,10.96,25.91
		c0,0.28,0,0.57,0,0.85C75.62,39.06,74.51,37.86,73.4,36.67z"
        />
      </g>
    </svg>
  </div>
</div>
