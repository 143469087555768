import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const DEFAULT_SIDEBAR_WIDTH = 'var(--ms-sidebar-default-width)';
const COLLAPSED_SIDEBAR_WIDTH = 'var(--ms-sidebar-collapsed-width)';

@Component({
  selector: 'ms-application-shell',
  templateUrl: './application-shell.component.html',
  styleUrls: ['./application-shell.component.scss'],
})
export class ApplicationShellComponent implements OnInit {
  // TOP
  @Input() public mainNav: MenuItem[];
  @Input() public userMenu: MenuItem[];
  @Input() public userProfile: any; // TODO
  public appSlug: string; // TODO: take name from module config
  @Input() public appName = ''; // TODO: cleanup

  // SIDEBAR
  @Input() public sidebarNav: MenuItem[] = [];
  public sidebarExpanded = true;
  public sidebarOverlayExpanded = false;
  public sidebarWidth: string = DEFAULT_SIDEBAR_WIDTH;
  private sidebarMouseOver$ = new Subject<boolean>();
  private sideBarSubscription = Subscription.EMPTY;

  public constructor(private router: Router) {}

  public get gdColumns() {
    if (!this.showSidebar) {
      return 'auto';
    }

    return this.sidebarExpanded
      ? DEFAULT_SIDEBAR_WIDTH
      : COLLAPSED_SIDEBAR_WIDTH;
  }
  public get gdAreas() {
    if (!this.showSidebar) {
      return 'ms-header | ms-content';
    }

    return 'ms-header ms-header | ms-sidebar ms-content';
  }

  public get showSidebar() {
    return !this.sidebarNav || this.sidebarNav.length > 0;
  }

  public get getAriaLabel() {
    const activeMenu = this.sidebarNav.find(
      ({ routerLink }) => routerLink === this.router.url
    );
    return activeMenu?.label || 'Page';
  }

  ngOnDestroy(): void {
    this.sideBarSubscription.closed || this.sideBarSubscription.unsubscribe();
  }

  public ngOnInit() {
    console.log(this.sidebarNav);
  }

  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
    this.sidebarWidth = this.sidebarExpanded
      ? DEFAULT_SIDEBAR_WIDTH
      : COLLAPSED_SIDEBAR_WIDTH;

    !this.sidebarExpanded ? this.enableOverlayMode() : this.enablePushMode();
  }

  public mouseEnter(): void {
    !this.sidebarExpanded && this.sidebarMouseOver$.next(true);
  }

  public mouseLeave(): void {
    !this.sidebarExpanded && this.sidebarMouseOver$.next(false);
  }

  private enableOverlayMode() {
    this.sideBarSubscription = this.sidebarMouseOver$
      .pipe(distinctUntilChanged(), debounceTime(400))
      .subscribe((isCursorOverSidebar) => {
        if (this.sidebarExpanded) {
          return;
        }

        if (isCursorOverSidebar) {
          this.sidebarWidth = DEFAULT_SIDEBAR_WIDTH;
          this.sidebarOverlayExpanded = true;

          return;
        }
        this.sidebarWidth = COLLAPSED_SIDEBAR_WIDTH;
        this.sidebarOverlayExpanded = false;
      });
  }

  private enablePushMode() {
    this.sidebarOverlayExpanded = false;
    this.sideBarSubscription.unsubscribe();
  }
}
