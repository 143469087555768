import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { EnvironmentConfigService } from './environment-config.service';
import { IMsConfigModuleConfig, MsConfigModuleConfig } from './models';

export function loadConfig(configService: EnvironmentConfigService) {
  return () => configService.loadConfig();
}

export class EnvironmentConfig {}

export const MS_ENVIRONMENT_CONFIG = new InjectionToken(
  'MS_ENVIRONMENT_CONFIG'
);

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class MsApplicationConfigModule {
  constructor(
    @Optional() @SkipSelf() parentModule?: MsApplicationConfigModule
  ) {
    if (parentModule) {
      throw new Error(
        'MsApplicationConfigModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot<T>(
    config: IMsConfigModuleConfig
  ): ModuleWithProviders<MsApplicationConfigModule> {
    if (!config.configUrl) {
      throw new Error('configUrl should be provided');
    }

    return {
      ngModule: MsApplicationConfigModule,
      providers: [
        { provide: MsConfigModuleConfig, useValue: config },
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [EnvironmentConfigService],
          useFactory: loadConfig,
        },
        {
          provide: MS_ENVIRONMENT_CONFIG,
          deps: [EnvironmentConfigService],
          useFactory: (configService: EnvironmentConfigService): T =>
            <T>configService.config,
        },
      ],
    };
  }
}
