import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private _ngZone: NgZone
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    return next
      .handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 200:
            case 401:
              break;
            default:
              if (
                req.url.includes('connect/token') ||
                (req.url.includes('/login') && error.status === 409)
              ) {
                break;
              }

              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  "Something went wrong, and we couldn't update your results. Please wait a few minutes and try again.",
              });
              break;
          }
          return throwError(error);
        })
      )
      .toPromise();
  }
}
