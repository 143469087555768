export interface IAuthModuleConfig {
  issuer: string;
  allowedUrls: Array<string>;
  app: string;
  params?: {
    [param: string]: string;
  };
}

export class AuthModuleConfig implements IAuthModuleConfig {
  products: { elttoUrl: string; miUrl: string };
  allowedUrls: Array<string> = [];
  app: string;
  issuer: string;
  url: string;
  params = {};
}
