<ng-container *ngIf="userProfile$ | async as profile">
  <ms-top-navigation-bar
    [initials]="profile.initials"
    [products]="checkProducts(profile.products)"
    appName="Analytics"
    [items]="topNavBarItems"
    [userMenuItems]="topNavBarUserMenuItems"
    [sticky]="true"
  ></ms-top-navigation-bar>

  <router-outlet></router-outlet>
</ng-container>
