<p-inputNumber
  id="ltv"
  placeholder="%"
  [useGrouping]="false"
  [styleClass]="styleClass"
  [min]="min"
  [max]="max"
  [maxlength]="maxLength"
  [formControl]="control"
  prefix="%"
>
</p-inputNumber>
