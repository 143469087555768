<div
  *ngIf="file"
  class="flex justify-content-between align-items-center file p-2 mb-2"
>
  <div class="flex">
    <i class="pi pi-file"></i>
    <div class="ml-2">
      {{ readonly ? "Uploaded - " : "" }}{{ file.fileName }}
    </div>
  </div>
  <i
    *ngIf="!readonly"
    class="pi pi-times mr-2 cursor-pointer"
    (click)="onDelete(file)"
  ></i>
</div>
