import { Component, Input } from '@angular/core';

@Component({
  selector: 'ms-strong-password-check',
  templateUrl: './strong-password-check.component.html',
  styleUrls: ['./strong-password-check.component.scss'],
})
export class StrongPasswordCheckComponent {
  @Input() password: string = '';

  get isMinLengthValid(): boolean {
    return this.password.length >= 8;
  }

  get isContainUppercase(): boolean {
    return !!this.password.match(/[A-Z]/);
  }

  get isContainLowercase(): boolean {
    return !!this.password.match(/[a-z]/);
  }

  get isContainNumber(): boolean {
    return !!this.password.match(/\d/);
  }

  get isContainSpecialCharacter(): boolean {
    return !!this.password.match(/[^A-Za-z0-9]/);
  }
}
