<label class="mb-2 block" [ngClass]="labelClass">{{ label }}</label>
<div class="flex" [class.flex-column]="isVertical">
  <div *ngFor="let radio of answers" class="my-2" [class.radio]="!isVertical">
    <p-radioButton
      [inputId]="radio.name"
      [value]="radio.value"
      [formControl]="control"
    >
    </p-radioButton>
    <label class="ml-2" [for]="radio.name">{{ radio.name }}</label>
  </div>
</div>
