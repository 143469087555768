import { Component, OnInit, inject } from '@angular/core';
import { AuthService, MsAuthState } from '@ms/angular-workspace/dist/core';
import { Store } from '@ngxs/store';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserRole } from '../../modules/secure/models/userProfile.model';

@Component({
  selector: 'mi-analytics-layout',
  templateUrl: './analytics-layout.component.html',
  styleUrls: ['./analytics-layout.component.scss'],
})
export class AnalyticsLayoutComponent implements OnInit {
  public userProfile$: Observable<any> = inject(Store).select(MsAuthState.user);

  topNavBarItems: MenuItem[] = [
    {
      label: 'Property Search',
      routerLink: '/secure/property-search',
    },
    {
      label: 'Workspace',
      routerLink: '/secure/workspace',
    },
    {
      label: 'Market Insights',
      routerLink: '/secure/market-insights',
    },
    {
      label: 'Tenant Research',
      routerLink: '/secure/tenant-research',
    },
    {
      label: 'Report Builder',
      routerLink: '/secure/report-builder',
    },
  ];

  topNavBarUserMenuItems: MenuItem[] = [
    {
      label: 'Account',
      url: `${environment.productUrls.authUrl}/secure/profile`,
    },
    {
      label: 'MountainSeed Help',
      url: `${environment.productUrls.authUrl}/secure/contact-us`,
      command: () => window.Appcues.track('Clicked MountainSeed Help'),
    },
    {
      label: 'Log Out',
      command: () => this.authService.logout(),
    },
  ];

  isElttoUIHidden = environment.hideElttoUi;

  constructor(private authService: AuthService) {}

  public ngOnInit() {
    this.userProfile$
      .pipe(filter((userProfile) => !!userProfile))
      .subscribe((profile) => {
        if (profile.miRoleId === UserRole.Admin) {
          this.topNavBarUserMenuItems = [
            {
              label: 'Account',
              url: `${environment.productUrls.authUrl}/secure/profile`,
            },
            {
              label: 'Administration',
              url: `${environment.productUrls.authUrl}/secure/administration/users`,
            },
            {
              label: 'MountainSeed Help',
              url: `${environment.productUrls.authUrl}/secure/contact-us`,
              command: () => window.Appcues.track('Clicked MountainSeed Help'),
            },
            {
              label: 'Log Out',
              command: () => this.authService.logout(),
            },
          ];
        }
      });
  }

  checkProducts(products: any[]) {
    return products.filter(
      (product) => !this.isElttoUIHidden || !product.label.includes('Eltto')
    );
  }
}
