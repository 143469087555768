import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';

export const codeFlowDefaultConfig: AuthConfig = {
  issuer: '',
  redirectUri: window.location.origin,
  clientId: 'angular-client',
  responseType: 'code',
  scope: 'openid profile email offline_access mountainseed-api',
  showDebugInformation: true,
  useSilentRefresh: false,
  timeoutFactor: 0.75,
  sessionChecksEnabled: false,
  // clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  // nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
};

export const oAuthModuleDefaultConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [],
    sendAccessToken: true,
  },
};
