import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'ms-radio-buttons',
  templateUrl: './ms-radio-buttons.component.html',
  styleUrls: ['./ms-radio-buttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MsRadioButtonsComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsRadioButtonsComponent extends ControlValueAccessorConnector {
  @Input() label: string;
  @Input() labelClass: string = '';
  @Input() isVertical: boolean = true;
  @Input() answers: { name: string; value: boolean }[];

  constructor(injector: Injector) {
    super(injector);
  }
}
