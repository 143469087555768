import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MsWaffleMenuComponent } from './ms-waffle-menu.component';

@NgModule({
  imports: [
    CommonModule,
    //
    FontAwesomeModule,
    //
    ButtonModule,
    MenuModule,
  ],
  exports: [MsWaffleMenuComponent],
  declarations: [MsWaffleMenuComponent],
  providers: [],
})
export class MsWaffleMenuModule {}
