<p-inputNumber
  [styleClass]="styleClass"
  id="amount"
  [placeholder]="placeholder"
  [useGrouping]="false"
  [min]="0"
  [formControl]="control"
  mode="currency"
  [currency]="currency"
  locale="en-US"
  [minFractionDigits]="minFractionDigits"
  [maxlength]="maxLength"
>
</p-inputNumber>
