import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionsManager } from '@ms/angular-workspace/dist/core';

@Component({
  selector: 'ms-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent
  extends SubscriptionsManager
  implements OnInit
{
  @Input() inputValue: string = '';
  public inputChange = new Subject<string>();
  @Output() search = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() showIcon: boolean = true;
  @Input() debounceTimeInMs: number = 1000;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subs = this.inputChange
      .pipe(debounceTime(this.debounceTimeInMs), distinctUntilChanged())
      .subscribe((value: string) => {
        this.search.next(value);
      });
  }

  onSearch(event: Event) {
    const element = event.target as HTMLInputElement;
    this.inputChange.next(element.value);
  }
}
