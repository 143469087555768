<ms-loader *ngIf="isLoading"></ms-loader>
<div *ngIf="serverErrorMessages" class="my-5">
  <p-messages
    [(value)]="serverErrorMessages"
    [enableService]="false"
  ></p-messages>
</div>
<div
  class="dragged-files-wrapper mb-4"
  *ngIf="files?.length && selectedFilesOnTop"
>
  <div *ngFor="let file of files">
    <div
      class="
        file
        white-space-nowrap
        overflow-hidden
        p-2
        mt-2
        inline-block
        flex
        justify-content-between
        w-25rem
      "
    >
      <div class="file-name">
        <i class="pi pi-file mr-1"></i>
        {{ file.name }}
      </div>
      <i class="pi pi-times mr-2 cursor-pointer" (click)="remove(file)"></i>
    </div>
  </div>
</div>
<div
  draggable="true"
  *ngIf="allowMultiple || files?.length === 0"
  [ngClass]="dragAreaClass"
  class="
    flex
    justify-content-center
    align-items-center
    border-round
    mt-2
    mb-1
    py-5
  "
>
  <div class="flex flex-column align-items-center">
    <div *ngIf="mainLabel" class="flex align-items-center mb-3">
      <i class="pi pi-file font-medium text-2xl mr-2 file-icon"></i>
      <div class="text-bold text-lg">{{ mainLabel }}</div>
    </div>
    <div class="text-center mx-2">
      Drag here to upload {{ typesToString(" or ") }} file or click to browse.
    </div>
    <button
      pButton
      type="button"
      label="Choose File"
      class="p-button-outlined mt-2 w-5"
      [disabled]="disabled"
      (click)="onClick(fileUpload)"
    ></button>
    <input
      type="file"
      [accept]="fileTypes ? typesToString(',') : 'images/*'"
      #fileUpload
      [multiple]="allowMultiple"
      (change)="onFileChange($event)"
      (blur)="onTouched($event)"
      class="hidden"
    />
  </div>
</div>
<div
  class="dragged-files-wrapper mt-4"
  *ngIf="files?.length && !selectedFilesOnTop"
>
  <div *ngFor="let file of files">
    <div
      class="
        file
        white-space-nowrap
        overflow-hidden
        p-2
        mt-2
        inline-block
        flex
        justify-content-between
        w-25rem
      "
    >
      <div class="file-name">
        <i class="pi pi-file mr-1"></i>
        {{ file.name }}
      </div>
      <i class="pi pi-times mr-2 cursor-pointer" (click)="remove(file)"></i>
    </div>
  </div>
</div>
