import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Component({
  selector: 'ms-modal',
  templateUrl: './ms-modal.component.html',
  styleUrls: ['./ms-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MsModalComponent implements AfterContentInit {
  footerTemplate: TemplateRef<any>;
  contentTemplate: TemplateRef<any>;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  constructor() {}

  ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'ms-modal-footer':
          this.footerTemplate = item.template;
          break;

        case 'ms-modal-content':
          this.contentTemplate = item.template;
          break;

        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
}
