import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

interface IDropDownItem {
  id: number;
  name: string;
}

@Component({
  selector: 'ms-drop-down',
  templateUrl: './ms-drop-down.component.html',
  styleUrls: ['./ms-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsDropDownComponent implements OnInit {
  @Input() options: IDropDownItem[] = [];
  @Input() selectedOption: IDropDownItem;
  @Input() placeholder: string = 'Search';
  @Input() showClear: boolean = true;
  @Input() disabled: boolean = false;
  @Input() optionLabel: string = 'name';
  @Input() formControlName: string;
  @Output() select = new EventEmitter<string | number>();

  constructor() {}

  ngOnInit(): void {}

  changeStatus(value: string | number) {
    this.select.next(value);
  }
}
