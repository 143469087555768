<div class="date-range-picker-container flex flex-row w-20rem">
  <p-calendar
    [(ngModel)]="startDate"
    [ngClass]="
      calendarClass === undefined ? defaultStartCalendarClass : calendarClass
    "
    inputStyleClass="w-7rem"
    [showIcon]="true"
    dateFormat="mm/dd/yy"
    placeholder="MM/DD/YYYY"
    (ngModelChange)="startDateChanged($event)"
    (onSelect)="dateRangeChanged()"
    (onBlur)="dateRangeChanged()"
    [maxDate]="maxDate"
    msDateMask
  ></p-calendar>
  <span class="date-divider flex align-self-center">{{ "–" }}</span>
  <p-calendar
    [(ngModel)]="endDate"
    [showIcon]="true"
    [class]="
      calendarClass === undefined ? defaultEndCalendarClass : calendarClass
    "
    inputStyleClass="w-7rem"
    dateFormat="mm/dd/yy"
    placeholder="MM/DD/YYYY"
    (ngModelChange)="endDateChanged($event)"
    (onSelect)="dateRangeChanged()"
    (onBlur)="dateRangeChanged()"
    [maxDate]="maxDate"
    msDateMask
  >
  </p-calendar>
</div>
