import { AfterViewInit, Directive } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import 'inputmask';

@Directive({
  selector: '[msDateMask]',
})
export class DateMaskDirective implements AfterViewInit {
  constructor(private primeCalendar: Calendar) {}

  ngAfterViewInit() {
    new Inputmask('99/99/9999').mask(this.getHTMLInput());
  }

  getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }
}
