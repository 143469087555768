export interface Application {
  name: string;
  baseUrl?: string;
  logoUrl?: string;
}

export interface Apis {
  [key: string]: ApiConfig;

  default: ApiConfig;
}

export interface ApiConfig {
  [key: string]: string;

  url: string;
}

export interface IMsConfigModuleConfig {
  configUrl: string;
}

export class MsConfigModuleConfig implements IMsConfigModuleConfig {
  configUrl: string;
}
