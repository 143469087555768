import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(value: any[], separator: string = ', ', key?: string): string {
    if (!value) return '';

    if (key) {
      return value.map((item) => item[key]).join(separator);
    }

    return value.join(separator);
  }
}
