import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { MsSortIconComponent } from './ms-sort-icon/ms-sortIcon.component';
import { TableComponent } from './table.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TableModule,
    TriStateCheckboxModule,
    FormsModule,
    MenuModule,
    ButtonModule,
    SkeletonModule,
  ],
  exports: [TableComponent],
  declarations: [TableComponent, MsSortIconComponent],
  providers: [],
})
export class MsTableModule {}
