import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { of, Subject } from 'rxjs';

export interface IConfirmOnDeactivate {
  confirmationMessage?: string;

  accept?(): Function;

  reject?(): Function;

  needConfirmation(): boolean;
}

@Injectable()
export class CanDeactivateConfirmationGuard
  implements CanDeactivate<IConfirmOnDeactivate>
{
  public apply = new Subject<boolean>();

  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(
    component: IConfirmOnDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (component.needConfirmation()) {
      this.confirmationService.confirm({
        message: component.confirmationMessage,
        accept: () => {
          if (component.accept) {
            component.accept()();
          }
          this.apply.next(true);
        },
        reject: () => {
          if (component.reject) {
            component.reject()();
          }
          this.apply.next(false);
        },
      });
      return this.apply.asObservable();
    }
    return of(true);
  }
}
