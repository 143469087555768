import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpResponse } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthResourceServerErrorHandler,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { codeFlowDefaultConfig } from './configs';
import { AuthGuard } from './guards';
import { AuthModuleConfig } from './models';
import { AuthService } from './services';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): Storage {
  return sessionStorage;
}

export class OAuthHttpErrorHandler implements OAuthResourceServerErrorHandler {
  constructor(private storage: Storage, private config: AuthModuleConfig) {}

  handleError(err: any): Observable<any> {
    if (err instanceof HttpResponse) {
      if (err.status === 401) {
        this.storage.clear();
        window.location.href = `${this.config.url}/?ReturnUrl=${window.location.href}`;
      }
    }

    return throwError(err);
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, OAuthModule.forRoot()],
  exports: [],
  providers: [],
})
export class MsAuthModule {
  constructor(@Optional() @SkipSelf() parentModule?: MsAuthModule) {
    if (parentModule) {
      throw new Error(
        'MsAuthModule: is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(config: AuthModuleConfig): ModuleWithProviders<MsAuthModule> {
    if (!config.issuer) {
      throw new Error('MsAuthModule: issuer is required');
    }

    if (0 === config.allowedUrls.length) {
      throw new Error('MsAuthModule: allowedUrls should not be empty array');
    }

    return {
      ngModule: MsAuthModule,
      providers: [
        { provide: AuthModuleConfig, useValue: config },
        {
          provide: AuthConfig,
          useValue: { ...codeFlowDefaultConfig, issuer: config.issuer },
        },
        {
          provide: OAuthModuleConfig,
          useValue: {
            resourceServer: {
              allowedUrls: config.allowedUrls,
              sendAccessToken: true,
            },
          },
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        {
          provide: OAuthResourceServerErrorHandler,
          deps: [OAuthStorage, AuthModuleConfig],
          useFactory: (storage: Storage, config: AuthModuleConfig) =>
            new OAuthHttpErrorHandler(storage, config),
        },
        AuthService,
        AuthGuard,
      ],
    };
  }
}
