import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { State, StateContext, Selector } from '@ngxs/store';

@Injectable()
export class BrowserStateModel {
  firstTimePropertySearch: boolean;
  firstTimePropertySearchSorting: boolean;
  firstTimeHistoricalPropertyDetails: boolean;
  firstTimeHistoricalWorkspace: boolean;
  tableauTimeoutCouter: number;
  tableauTimeoutFilters: any;
  tableauTimeoutTimestamp: Date | undefined;
}

@State<BrowserStateModel>({
  name: 'browser',
  defaults: {
    firstTimePropertySearch: true,
    firstTimePropertySearchSorting: true,
    firstTimeHistoricalPropertyDetails: true,
    firstTimeHistoricalWorkspace: true,
    tableauTimeoutCouter: 0,
    tableauTimeoutFilters: {},
    tableauTimeoutTimestamp: undefined,
  },
})
@Injectable()
export class BrowserState {
  @Selector()
  public static getFirstTimePropertySearch(state: BrowserStateModel) {
    return state.firstTimePropertySearch;
  }

  @Selector()
  public static getFirstTimePropertySearchSorting(state: BrowserStateModel) {
    return state.firstTimePropertySearchSorting;
  }

  @Selector()
  public static getFirstTimeHistoricalPropertyDetails(
    state: BrowserStateModel
  ) {
    return state.firstTimeHistoricalPropertyDetails;
  }

  @Selector()
  public static getFirstTimeHistoricalWorkspace(state: BrowserStateModel) {
    return state.firstTimeHistoricalWorkspace;
  }

  @Selector()
  public static getTableauTimeoutFilters(state: BrowserStateModel) {
    return state.tableauTimeoutFilters;
  }

  @Selector()
  public static getTableauTimeoutCounter(state: BrowserStateModel) {
    return state.tableauTimeoutCouter;
  }

  @Selector()
  public static getTableauTimeoutTimestamp(state: BrowserStateModel) {
    return state.tableauTimeoutTimestamp;
  }

  @Receiver({ type: '[Browser] Set Browser Status Property Search' })
  public static setFirstTimePropertySearch(
    context: StateContext<BrowserStateModel>
  ) {
    context.patchState({
      firstTimePropertySearch: false,
    });
  }

  @Receiver({ type: '[Browser] Set Browser Status Property Search Sorting' })
  public static setFirstTimePropertySearchSorting(
    context: StateContext<BrowserStateModel>
  ) {
    context.patchState({
      firstTimePropertySearchSorting: false,
    });
  }

  @Receiver({
    type: '[Browser] Set Browser Status Historical Property Details',
  })
  public static setFirstTimeHistoricalPropertyDetails(
    context: StateContext<BrowserStateModel>
  ) {
    context.patchState({
      firstTimeHistoricalPropertyDetails: false,
    });
  }

  @Receiver({ type: '[Browser] Set Browser Status Historical Workspace' })
  public static setFirstTimeHistoricalWorkspace(
    context: StateContext<BrowserStateModel>
  ) {
    context.patchState({
      firstTimeHistoricalWorkspace: false,
    });
  }

  @Receiver({ type: '[Browser] Set Tableau Timeout Attempts' })
  public static setTableauTimeoutAttempt(
    context: StateContext<BrowserStateModel>,
    action: EmitterAction<any>
  ) {
    context.patchState({
      tableauTimeoutCouter: context.getState().tableauTimeoutCouter + 1,
      tableauTimeoutFilters: action.payload,
      tableauTimeoutTimestamp:
        context.getState().tableauTimeoutTimestamp ?? new Date(),
    });
  }

  @Receiver({ type: '[Browser] Reset Tableau Timeout Attempts' })
  public static resetTableauTimeoutAttempts(
    context: StateContext<BrowserStateModel>
  ) {
    context.patchState({
      tableauTimeoutCouter: 0,
      tableauTimeoutFilters: {},
      tableauTimeoutTimestamp: undefined,
    });
  }
}
