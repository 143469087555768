import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'ms-dialog',
  templateUrl: './ms-dialog.component.html',
  styleUrls: ['./ms-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsDialogComponent implements OnInit {
  @Input() title: string;
  @Input() headerClass: string;
  @Input() rejectLabel: string;
  @Input() acceptLabel: string;
  @Input() key: string;

  constructor() {}

  ngOnInit(): void {}
}
