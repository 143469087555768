import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumber implements PipeTransform {
  transform(rawNum: string) {
    const countryCode = rawNum.slice(0, 3);
    const centralOffice = rawNum.slice(3, 6);
    const phoneNumber = rawNum.slice(6, 10);

    return `${countryCode}-${centralOffice}-${phoneNumber}`;
  }
}
