<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  styleClass="w-5"
  [key]="key"
>
  <ng-template pTemplate="header">
    <h3 [ngClass]="headerClass">{{ title }}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      class="p-button-raised p-button-secondary p-button-text"
      icon="pi pi-times"
      [label]="rejectLabel"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Continue"
      (click)="cd.accept()"
      [label]="acceptLabel"
    ></button>
  </ng-template>
</p-confirmDialog>
