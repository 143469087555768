import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._isAuthenticated(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._isAuthenticated(state.url);
  }

  private _isAuthenticated(returnUrl: string) {
    return this.authService.isAuthenticated$
      .pipe(
        tap((isAuthenticated) =>
          console.log(
            '[AuthGuard] isAuthenticated=',
            isAuthenticated,
            returnUrl
          )
        )
      )
      .pipe(
        tap((isAuthenticated) => {
          if (!isAuthenticated) {
            setTimeout(() => {
              this.authService.login(returnUrl);
            });
          }
        })
      );
  }
}
