import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthModuleConfig } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly isDoneLoadingSubject$ = new ReplaySubject<boolean>(1);
  private _isDoneLoading$ = this.isDoneLoadingSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  private readonly isAuthenticatedSubject$ = new ReplaySubject<boolean>(1);
  private _isAuthenticated$ = this.isAuthenticatedSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  public isAuthenticated$ = combineLatest([
    this._isAuthenticated$,
    this._isDoneLoading$,
  ]).pipe(
    map((values) => values.every((b) => b)),
    distinctUntilChanged()
  );

  private readonly accountProfileSubject$ = new Subject<any>();
  public accountProfile$ = this.accountProfileSubject$.asObservable();

  constructor(
    private config: AuthModuleConfig,
    private oauthService: OAuthService,
    private router: Router
  ) {
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error('[AuthService] OAuthErrorEvent Object:', event);
      } else {
        console.warn('[AuthService] OAuthEvent Object:', event);
      }
    });

    this.isAuthenticated$
      .pipe(
        filter((isAuth) => isAuth),
        switchMap(() => this.oauthService.loadUserProfile())
      )
      .subscribe((user) => {
        const profile = (<any>user)?.info;
        const { isElttoSubscribed, isMISubscribed } = profile;
        const mi = {
          label: 'MountainSeed Analytics',
          url: this.config.products.miUrl,
        };
        const eltto = {
          label: 'Eltto',
          url: this.config.products.elttoUrl,
        };

        this.accountProfileSubject$.next({
          ...profile,
          displayName: !profile.firstName
            ? profile.email
            : `${profile?.firstName || ''} ${profile?.lastName || ''} ${
                profile?.name?.suffix || ''
              }`,
          initials: `${profile.firstName ? profile.firstName[0] : ''}${
            profile.lastName ? profile.lastName[0] : ''
          }`,
          products: [isElttoSubscribed && eltto, isMISubscribed && mi].filter(
            Boolean
          ),
        });
      });

    this.oauthService.events
      .pipe(
        filter((e) =>
          [
            'session_terminated',
            'session_error',
            'user_profile_load_error',
          ].includes(e.type)
        )
      )
      .subscribe((e) => {
        this.login();
      });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(
      targetUrl || this.router.url,
      this.config.params
    );
  }

  public logout() {
    this.oauthService.logOut();
    sessionStorage.clear();
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.table(
        location.hash
          .substr(1)
          .split('&')
          .map((kvp) => kvp.split('='))
      );
    }

    return this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then((_) => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        if (
          !this.oauthService.hasValidAccessToken() &&
          this.oauthService.getRefreshToken()
        ) {
          return this.oauthService
            .refreshToken()
            .then((res) => {
              return Promise.resolve();
            })
            .catch((reason) => {
              return Promise.reject('[AuthService] Unable to refresh');
            });
        }

        return Promise.reject('[AuthService] Not authenticated');
      })
      .then((res) => {
        this.isDoneLoadingSubject$.next(true);
        if (
          this.oauthService.state &&
          this.oauthService.state !== 'undefined' &&
          this.oauthService.state !== 'null'
        ) {
          let stateUrl = this.oauthService.state;
          if (!stateUrl.startsWith('/')) {
            stateUrl = decodeURIComponent(stateUrl);
          }

          return this.router.navigateByUrl(stateUrl).then((success) => {
            return Promise.resolve();
          });
        } else {
          return Promise.resolve();
        }
      })
      .catch((x) => {
        this.isDoneLoadingSubject$.next(true);
        return Promise.reject('[AuthService] Unable to refresh token');
      });
  }
}
