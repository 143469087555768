import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuModule } from 'primeng/menu';

import { MsSidebarMenuComponent } from './sidebar-menu.component';

@NgModule({
  imports: [CommonModule, MenuModule],
  exports: [MsSidebarMenuComponent],
  declarations: [MsSidebarMenuComponent],
  providers: [],
})
export class MsSidebarMenuModule {}
