import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

interface IDropDownItem {
  id: number;
  name: string;
}

export namespace MsDifferentValidators {
  export function dropDownEqualsValue(input: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const value: IDropDownItem = c?.value;
      return value?.name === input
        ? null
        : {
            dropDownNotEqualsValue: { valid: false },
          };
    };
  }
}
